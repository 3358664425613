import React from 'react'
import { useEffect,useState } from 'react';

function Auth() {

    if(sessionStorage.getItem('userID') == null){
        window.location.href = "/login";
    };
    const userId = sessionStorage.getItem('userID');


    
    
    


    useEffect(() => {
        // This function will run when the component is mounted
        
        getUserDeatisl()

        getAdminData()
      }, []);

      const getUserDeatisl = async () =>{
        try {
            


          const response = await fetch('https://api.rainocoin.com/userDetails.php?userId='+userId)
                      .then(res => res.json())
                      .then(
                      (result) => {

                          if(result.error == "200"){

                            localStorage.setItem('id', result.id);
                            localStorage.setItem('sponsor_id', result.sponsor_id);
                            localStorage.setItem('name', result.name);
                            localStorage.setItem('mobile', result.mobile);
                            localStorage.setItem('myBusiness', result.myBusiness);
                            localStorage.setItem('teamBusiness', result.teamBusiness);
                            localStorage.setItem('allBusiness', result.allBusiness);
                            localStorage.setItem('joidDate', result.joidDate);
                            localStorage.setItem('joinTime', result.joinTime);
                            localStorage.setItem('lastLogin', result.lastLogin);
                            localStorage.setItem('LoginCount', result.LoginCount);
                            localStorage.setItem('accountStatus', result.accountStatus);
                            localStorage.setItem('signupPoint', result.signupPoint);
                            localStorage.setItem('referralPoint', result.referralPoint);
                            localStorage.setItem('totalPoint', result.totalPoint);
                            localStorage.setItem('usedPoint', result.usedPoint);
                            localStorage.setItem('transferPoint', result.transferPoint);
                            localStorage.setItem('avlPoint', result.avlPoint);
                            localStorage.setItem('directIncome', result.directIncome);
                            localStorage.setItem('depositAmount', result.depositAmount);
                            localStorage.setItem('totalAmount', result.totalAmount);
                            localStorage.setItem('directWithdraw', result.directWithdraw);
                            localStorage.setItem('directWithdraw', result.directWithdraw);
                            localStorage.setItem('directAvailable', result.directAvailable);
                            localStorage.setItem('stakeAmount', result.stakeAmount);
                            localStorage.setItem('stakeCoin', result.stakeCoin);
                            localStorage.setItem('finalCoin', result.finalCoin);
                            localStorage.setItem('stakeCredit', result.stakeCredit);
                            localStorage.setItem('teamCredit', result.teamCredit);
                            localStorage.setItem('creditCoin', result.creditCoin);
                            localStorage.setItem('remainCoin', result.remainCoin);
                            localStorage.setItem('withdrawCoin', result.withdrawCoin);
                            localStorage.setItem('swapCoin', result.swapCoin);
                            localStorage.setItem('avlCoin', result.avlCoin);
                            localStorage.setItem('withdrawStatus', result.withdrawStatus);
                            localStorage.setItem('swapStatus', result.swapStatus);
                            localStorage.setItem('directReferral', result.directReferral);
                            localStorage.setItem('totalTeam', result.totalTeam);
                            localStorage.setItem('team1', result.team1);
                            localStorage.setItem('team2', result.team2);
                            localStorage.setItem('team3', result.team3);
                            localStorage.setItem('team4', result.team4);
                            localStorage.setItem('team5', result.team5);
                            localStorage.setItem('team6', result.team6);
                            localStorage.setItem('team7', result.team7);
                            localStorage.setItem('team8', result.team8);
                            localStorage.setItem('team9', result.team9);
                            localStorage.setItem('team10', result.team10);
                            localStorage.setItem('team11', result.team11);
                            localStorage.setItem('team12', result.team12);
                            localStorage.setItem('team13', result.team13);
                            localStorage.setItem('team14', result.team14);
                            localStorage.setItem('team15', result.team15);
                            localStorage.setItem('businessLevel1', result.businessLevel1);
                            localStorage.setItem('businessLevel2', result.businessLevel2);
                            localStorage.setItem('businessLevel3', result.businessLevel3);
                            localStorage.setItem('businessLevel4', result.businessLevel4);
                            localStorage.setItem('businessLevel5', result.businessLevel5);
                            localStorage.setItem('businessLevel6', result.businessLevel6);
                            localStorage.setItem('businessLevel7', result.businessLevel7);
                            localStorage.setItem('businessLevel8', result.businessLevel8);
                            localStorage.setItem('businessLevel9', result.businessLevel9);
                            localStorage.setItem('businessLevel10', result.businessLevel10);
                            localStorage.setItem('businessLevel11', result.businessLevel11);
                            localStorage.setItem('businessLevel12', result.businessLevel12);
                            localStorage.setItem('businessLevel13', result.businessLevel13);
                            localStorage.setItem('businessLevel14', result.businessLevel14);
                            localStorage.setItem('businessLevel15', result.businessLevel15);
                            localStorage.setItem('rewardLevel1', result.rewardLevel1);
                            localStorage.setItem('rewardLevel2', result.rewardLevel2);
                            localStorage.setItem('rewardLevel3', result.rewardLevel3);
                            localStorage.setItem('rewardLevel4', result.rewardLevel4);
                            localStorage.setItem('rewardLevel5', result.rewardLevel5);
                            localStorage.setItem('rewardLevel6', result.rewardLevel6);
                            localStorage.setItem('rewardLevel7', result.rewardLevel7);
                            localStorage.setItem('rewardLevel8', result.rewardLevel8);
                            localStorage.setItem('rewardLevel9', result.rewardLevel9);
                            localStorage.setItem('rewardLevel10', result.rewardLevel10);
                            localStorage.setItem('rewardLevel11', result.rewardLevel11);
                            localStorage.setItem('rewardLevel12', result.rewardLevel12);
                            localStorage.setItem('rewardLevel13', result.rewardLevel13);
                            localStorage.setItem('rewardLevel14', result.rewardLevel14);
                            localStorage.setItem('rewardLevel15', result.rewardLevel15);
                            localStorage.setItem('wallet_address', result.wallet_address);


                            

                            if(result.mailStatus !== 'verified'){

                              sessionStorage.clear();

                              localStorage.clear();

                              window.location.href = "/login";
                            };

                          };
                          if(result.error == "100"){
                           //   window.location.href = "/login";
                          };
                       }
                      )
            
        } catch (error) {

            console.log(error);
            
        }

      }


      const getAdminData = async () =>{
        try {
            


          const responseData = await fetch('https://api.rainocoin.com/adminData.php')
                      .then(res => res.json())
                      .then(
                      (adminData) => {

                          if(adminData.error == "200"){

                            localStorage.setItem('adminCoinPrice', adminData.coinPrice);
                            localStorage.setItem('adminDepositAddress', adminData.depositAddress);
                            localStorage.setItem('adminWithdrawAddress', adminData.withdrawAddress);
                            localStorage.setItem('adminWithdrawKey', adminData.withdrawKey);
                            localStorage.setItem('adminWithdrawStatus', adminData.withdrawStatus);
                            localStorage.setItem('adminDepositStatus', adminData.depositStatus);
                            localStorage.setItem('adminMinDeposit', adminData.minDeposit);
                          };
                          if(adminData.error == "100"){
                           //   window.location.href = "/login";
                          };
                       }
                      )
            
        } catch (error) {

            console.log(error);
            
        }

      }

  return (
    <>
    </>
  )
}

export default Auth