import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import History from './Pages/History';
import Rewards from './Pages/Rewards';
import Profile from './Pages/Profile';
import Qrcode from './Pages/Qrcode';
import Topup from './Pages/Topup';
import Bill from './Pages/Bill';
import Register from './Pages/Register';
import Verifyaccount from './Pages/Verifyaccount';
import Login from './Pages/login';
import Setting from './Pages/Setting';
import Referral from './Pages/referral';
import Wallet from './Pages/wallet';
import Transfer from './Pages/transfer';
import Myteam from './Pages/myteam';
import Withdraw from './Pages/withdraw';
import Stake from './Pages/stake.js';
import Myreward from './Pages/myreward';
import Teamreward from './Pages/teamreward';
import Trade from './Pages/trade';
function App() {
  return (
    <BrowserRouter>
    <Routes>
         <Route excat path='/' element={<Home />} />
         <Route  path='/history' element={<History />} />
         <Route  path='/referral' element={<Referral />} />
         <Route  path='/rewards' element={<Rewards />} />
         <Route  path='/profile' element={<Profile/>}/>
         <Route path='/qr-code' element={<Qrcode/>}/>
         <Route  path='/topup' element={<Topup/>}/>
         <Route  path='/bill' element={<Bill/>}/>
         <Route  path='/Login' element={<Login/>}/>
         <Route path='/Register'  element={<Register/>}/>
         <Route path='/Verifyaccount' element={<Verifyaccount/>}/>
         <Route path='/Setting' element={<Setting/>}/>
         <Route path='/wallet' element={<Wallet/>}/>
         <Route path='/transfer' element={<Transfer/>}/>
         <Route path='/myteam' element={<Myteam/>}/>
         <Route path='/withdraw' element={<Withdraw/>}/>
         <Route path='/stake' element={<Stake/>}/>
         <Route path='/myreward' element={<Myreward/>}/>
         <Route path='/teamreward' element={<Teamreward/>}/>
         <Route path='/trade' element={<Trade/>}/>

         
    </Routes>

</BrowserRouter>
  );
}

export default App;
