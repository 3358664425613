import React from 'react'
import { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Verifyaccount() {
    const Api = process.env.REACT_APP_API_URL
    const apiKey = process.env.REACT_APP_API_KEY

    const [opt1, setOtp1] = useState(null);
    const [opt2, setOtp2] = useState(null);
    const [opt3, setOtp3] = useState(null);
    const [opt4, setOtp4] = useState(null);

    const userOtp = opt1+opt2+opt3+opt4;

    const submitOtp =()=>{
       var mail =  localStorage.getItem('otpmail');
       if(opt1 != "" && opt2 != "" &&  opt3 != "" &&  opt3 != "" ){

        const response =  fetch(Api+'verifyOTP.php?email='+mail+'&otp='+userOtp+'&type=accountVerify')
        .then(res => res.json())
        .then(
        (result) => {

            console.log("Return Data", result);
            if(result.error == "200"){

                toast.success(result.message,{
                    position: toast.POSITION.TOP_RIGHT,
                });


                window.location.href = "/login";
            
            };

            if(result.error == "100"){


                toast.error(result.message ,{
                    position: toast.POSITION.TOP_RIGHT,
                });
            
            };

        }
        )

            if (!response.ok) {
            console.log("fetch failed")
            }

       }else{

        toast.error('Enter all Field',{
            position: toast.POSITION.TOP_RIGHT,
        });

       }
        console.log(userOtp+' & '+mail);
    };


    return (

        <div>
            <div className="header">
            <ToastContainer />
                <div className="tf-container">
                    <div className="tf-statusbar br-none d-flex justify-content-center align-items-center">
                        <a href="#" className="back-btn"> <i className="icon-left" /> </a>
                    </div>
                </div>
            </div>
            <div className="verify-account-section">
                <div className="tf-container">
                    <div >
                        <img src="assets/images/user/register.jpg" alt="images" />
                    </div>
                    <div className="tf-content mt-2">
                        <h3 className="mb-2 text-center">Please Verify Account</h3>
                    </div>
                    <div className="mt-5">
                        <div className="tf-container">
                            <form className="tf-form tf-form-verify" action="07_verify-account.html">
                                <div className="d-flex group-input-verify">
                                    <input type="tel" maxlength="1" pattern="[0-9]" className="input-verify" onChange={(event)=>setOtp1(event.target.value)} fdprocessedid="n0y9af" />
                                    <input type="tel" maxlength="1" pattern="[0-9]" className="input-verify" onChange={(event)=>setOtp2(event.target.value)} fdprocessedid="v6veil" />
                                    <input type="tel" maxlength="1" pattern="[0-9]" className="input-verify" onChange={(event)=>setOtp3(event.target.value)} fdprocessedid="kirin" />
                                    <input type="tel" maxlength="1" pattern="[0-9]" className="input-verify"  onChange={(event)=>setOtp4(event.target.value)} fdprocessedid="9kai6c" />
                                </div>
                                <div className="text-send-code">
                                    <p className="fw_4">A code has been sent to your email</p>
                                    
                                </div>
                            </form>
                        </div>

                    </div>
                    <a href="javascript:void(0)" onClick={submitOtp}  className="tf-btn accent large mt-5">VERIFY</a>
                </div>
            </div>
        </div>
    );
}

export default Verifyaccount