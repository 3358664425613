import React from 'react'
import { Link } from 'react-router-dom';
function Qrcode() {
  return (
    <>
      <div>
        <div className="header">
          <div className="tf-container">
            <div className="tf-statusbar d-flex justify-content-center align-items-center">
              <Link to="/" className="back-btn"> <i className="icon-left" /> </Link>
              <h3>QR Code</h3>
            </div>
          </div>
        </div>
        <div className="wrap-qr">
          <div className="tf-container">
            <h2 className="fw_6 text-center">Screen Your QR Code</h2>
            <div className="logo-qr">
              <img src="assets/images/scan-qr/qrcode1.png" alt="image" />
            </div>
          </div>
        </div>
        <div className="bottom-navigation-bar bottom-btn-fixed">
          <div className="tf-container d-flex gap-3">
            <Link to="#" className="tf-btn accent medium">Payment QR</Link>
            <Link to="#" className="tf-btn outline medium">Scan QR</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Qrcode;