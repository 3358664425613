import React from 'react'
import Auth from '../Components/auth';
import { ToastContainer,toast } from 'react-toastify';

function Setting() {


    const logOut = ()=>{


        sessionStorage.clear();

        localStorage.clear();

        toast.success("Logut Success",{ position: toast.POSITION.TOP_RIGHT, });

        window.location.href = "/login";

    }
    return (
        <div>
        <Auth/>
        <ToastContainer />
            <div className="header">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <a href="/" className="back-btn"> <i className="icon-left" /> </a>
                        <h3>Setting</h3>
                    </div>
                </div>
            </div>
            <div className="mt-1 box-settings-profile style1">
                <a className="list-setting-profile" href="javascript:void(0)">
                    <div className="inner-left">
                        <h4 className="fw_6">Change Password</h4>
                    </div>
                    <span className="inner-right"><i className="icon-right" /></span>
                </a>
                <a className="list-setting-profile" href="javascript:void(0)">
                    <div className="inner-left">
                        <h4 className="fw_6">Security Center</h4>
                    </div>
                    <span className="inner-right"><i className="icon-right" /></span>
                </a>

                {/* <div className="list-setting-profile">
                    <div className="inner-left">
                        <h4 className="fw_6">Dark/Light Mode</h4>
                    </div>
                    <a href="#" className="inner-right">
                        <input className="tf-switch-check" id="toggle-theme" type="checkbox" defaultValue="checkbox" name="check" />
                    </a>
                </div> */}
                <a className="list-setting-profile" href="javascript:void(0)">
                    <div className="inner-left">
                        <h4 className="fw_6">Setting Notification</h4>
                    </div>
                    <span className="inner-right"><i className="icon-right" /></span>
                </a>
                
            </div>
            <a className="mt-1 list-setting-profile style1" href="javascript:void(0)" id="btn-popup-up">
                <h4 className="fw_6">Language</h4>
                <span className="inner-right">English <i className="icon-right" /> </span>
            </a>
            <a className="mt-1 list-setting-profile style1" onClick={logOut} id="btn-logout">
                <h4 className="fw_6 critical_color">Log Out</h4>
                <span className="inner-right"> <i className="icon-right" /> </span>
            </a>
            <div className="tf-panel logout">
                <div className="panel_overlay" />
                <div className="panel-box panel-center panel-logout">
                    <div className="heading">
                        <h2 className="text-center">Do you really want to sign out of your account?</h2>
                    </div>
                    <div className="bottom">
                        <a className="clear-panel" href="#">Cancel</a>
                        <a className="clear-panel critical_color" href="#">Log Out</a>
                    </div>
                </div>
            </div>
            <div className="tf-panel lock-app">
                <div className="panel_overlay" />
                <div className="panel-box panel-up panel-st-app">
                    <div className="d-flex justify-content-center">
                        <span className="line" />
                    </div>
                    <h3 className="panel-title fw_6 text-center">Automatically lock the following app</h3>
                    <ul className="panel-content mt-3">
                        <li className="item clear-panel"><a href="#">30 seconds <i className="icon-checkmark" /></a></li>
                        <li className="item clear-panel"><a href="#">1 minute <i className="icon-checkmark" /></a></li>
                        <li className="item clear-panel"><a href="#">2 minute <i className="icon-checkmark" /></a></li>
                        <li className="item clear-panel"><a href="#">5 minute <i className="icon-checkmark" /></a></li>
                        <li className="item clear-panel"><a href="#" className="active">No auto-lock <i className="icon-checkmark" /></a></li>
                    </ul>
                </div>
            </div>
            <div className="tf-panel up">
                <div className="panel_overlay" />
                <div className="panel-box panel-up panel-st-app">
                    <div className="d-flex justify-content-center">
                        <span className="line" />
                    </div>
                    <h3 className="panel-title fw_6 text-center">Language</h3>
                    <ul className="panel-content mt-3">
                        <li className="item clear-panel"><a href="#" className="active">English<i className="icon-checkmark" /></a></li>
                        <li className="item clear-panel"><a href="#">VietNam <i className="icon-checkmark" /></a></li>
                        <li className="item clear-panel"><a href="#">Portugal <i className="icon-checkmark" /></a></li>
                        <li className="item clear-panel"><a href="#">Germany <i className="icon-checkmark" /></a></li>
                        <li className="item clear-panel"><a href="#">China <i className="icon-checkmark" /></a></li>
                        <li className="item clear-panel"><a href="#">Switzerland <i className="icon-checkmark" /></a></li>
                        <li className="item clear-panel"><a href="#">France <i className="icon-checkmark" /></a></li>
                    </ul>
                </div>
            </div>
        </div>
        
    );
}

export default Setting;