import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Auth from '../Components/auth';
import { useState } from 'react';
import { ToastContainer,toast } from 'react-toastify';
function Home() {


    const myData = localStorage.getItem('id');

    const [isMining,setMining]=useState(false);
    const [isMinButton,setMineButton]=useState(false);
    const [isMineStatus,setMineStatus]=useState(true);
    const [stakeid, setStakeId] = useState(null);

    const gameUrl = `https://games.rainocoin.com/home?userID=${myData}`;


    const startMining = ()=>{

        setMining(true);

        fetch('https://api.rainocoin.com/dailyMining.php?userid='+localStorage.getItem('id')+'&stakeId='+stakeid)
        .then(res => res.json())
        .then(
            (mineStatus) =>{

                //console.log(mineStatus);

                if(mineStatus.error === '200'){

                    setMining(false);
                    setMineStatus(true);

                    toast.success(mineStatus.message,{ position: toast.POSITION.TOP_RIGHT, });
                    
                 getStakeData();

                }

                if(mineStatus.error === '400'){

                    setMining(false);
                    setMineStatus(false);

                    toast.error(mineStatus.message,{ position: toast.POSITION.TOP_RIGHT, });
                }
            }
        )
    }


    const getStakeData = () =>{

        fetch('https://api.rainocoin.com/miningStatus.php?userid='+localStorage.getItem('id'))
        .then(res => res.json())
        .then(
            (stakeStatus) =>{

               // console.log(stakeStatus);

                if(stakeStatus.error === '200'){

                    setStakeId(stakeStatus.id);

                  //  console.log(stakeid);
                  if(stakeStatus.c_date === stakeStatus.MineDateTime){


                    setMineStatus(true);
                  }else{

                    setMineStatus(false);
                    setMining(false);

                  }

                };

                if(stakeStatus.error === '400'){

                    setMineStatus(false);
                    setMining(false);
                }
            }
        )
    }

    useEffect(() =>{

        getStakeData();
    
    },[])
   
    return (
        <div>
        <Auth/>
        <ToastContainer />
            <div className="app-header">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-between align-items-center">
                        <Link className="user-info d-flex justify-content-between align-items-center" to="/profile">
                            <img src="assets/images/user/profile1.jpg" alt="image" />
                            <div className="content">
                                <h4 className="white_color">{localStorage.getItem('name')}</h4>
                                <p className="white_color fw_4">Let’s save your money!</p>
                            </div>
                        </Link>
                        <Link to="#" id="btn-popup-up" className="icon-notification1"><span>2</span></Link>
                    </div>
                </div>
            </div>
            <div className="card-secton">
                <div className="tf-container">
                    <div className="tf-balance-box">
                        <div className="balance">
                            <div className="row">
                                <div className="col-6 br-right">
                                    <div className="inner-left">
                                        <p>Your Balance:</p>
                                        <h3>${localStorage.getItem('directAvailable')}</h3>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="inner-right">
                                        <p>Reward</p>
                                        <h3>
                                        <ul className="icon-gift-box">
                                                <li className="path1" />
                                                <li className="path2" />
                                                <li className="path3" />
                                                <li className="path4" />
                                                <li className="path5" />
                                                <li className="path6" />
                                                <li className="path7" />
                                                <li className="path8" />
                                                <li className="path9" />
                                                <li className="path10" />
                                            </ul>
                                            {localStorage.getItem('avlPoint')}
                                            <span>Raino</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wallet-footer">
                            <ul className="d-flex justify-content-between align-items-center">
                                <li className="wallet-card-item">
                                    <Link className="fw_6 text-center" id="btn-popup-down" to={gameUrl}>
                                        <img class='icon icon-topup' src='assets/images/gameicon.png'/>
                                        Games
                                    </Link>
                                </li>
                                <li className="wallet-card-item"><Link className="fw_6" to="/topup">
                                    <ul className="icon icon-topup">
                                        <li className="path1" />
                                        <li className="path2" />
                                        <li className="path3" />
                                        <li className="path4" />
                                    </ul>
                                    Deposit
                                </Link></li>
                                <li className="wallet-card-item"><Link className="fw_6 btn-card-popup" to="/wallet">
                                    <ul className="icon icon-group-credit-card">
                                        <li className="path1" />
                                        <li className="path2" />
                                        <li className="path3" />
                                    </ul>
                                    Wallet
                                </Link></li>
                                <li className="wallet-card-item"><Link className="fw_6" to="/withdraw">
                                    <ul className="icon icon-my-qr">
                                        <li className="path1" />
                                        <li className="path2" />
                                        <li className="path3" />
                                        <li className="path4" />
                                        <li className="path5" /> 
                                        <li className="path6" />
                                        <li className="path7" />
                                    </ul>
                                    Withdraw
                                </Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <div className="tf-container">
                    
                    <ul className="box-service mt-3">
                        <li>
                            <Link to="/referral">
                                <div className="icon-box bg_color_1">
                                <svg fill="#002db3" width="25px" height="25px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#002db3"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M18,14c-.053,0-.1.014-.155.016L14.817,8.838a4,4,0,1,0-5.632,0L6.166,14.017C6.109,14.014,6.057,14,6,14a4,4,0,1,0,3.859,5h4.282A3.994,3.994,0,1,0,18,14Zm-3.859,3H9.859a3.994,3.994,0,0,0-1.731-2.376l2.793-4.79a3.589,3.589,0,0,0,2.161,0l2.8,4.786A3.989,3.989,0,0,0,14.141,17Z"></path></g></svg>
                                </div>
                                Referrals
                            </Link>
                        </li>
                        <li>
                            <Link to="/myteam">
                                <div className="icon-box bg_color_2">
                                <i class="icon icon-user3" fill="#002db3" width="25px" height="25px" viewBox="0 0 24 24"></i>
                                </div>
                                Team
                            </Link>
                        </li>
                        <li>
                            <Link to="/stake">
                                <div className="icon-box bg_color_3">
                                    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.87511 1.25C5.49974 1.24954 5.1266 1.30757 4.76911 1.422C4.00043 1.65173 3.32708 2.12475 2.85029 2.76998C2.37349 3.4152 2.11901 4.19775 2.12511 5V10C2.12564 10.7292 2.41554 11.4284 2.93115 11.944C3.44676 12.4596 4.14592 12.7495 4.87511 12.75H8.87511C9.07402 12.75 9.26479 12.671 9.40544 12.5303C9.54609 12.3897 9.62511 12.1989 9.62511 12V5C9.62379 4.00585 9.22827 3.05279 8.5253 2.34981C7.82232 1.64684 6.86926 1.25133 5.87511 1.25Z" fill="#DA9B00" />
                                        <path d="M23.6248 6.00002V20C23.625 20.3612 23.5541 20.7189 23.416 21.0527C23.2779 21.3865 23.0753 21.6897 22.8199 21.9452C22.5645 22.2006 22.2612 22.4031 21.9275 22.5412C21.5937 22.6793 21.236 22.7503 20.8748 22.75H10.8748C10.5136 22.7503 10.1558 22.6793 9.82208 22.5412C9.48831 22.4031 9.18505 22.2006 8.92964 21.9452C8.67422 21.6897 8.47167 21.3865 8.33356 21.0527C8.19545 20.7189 8.1245 20.3612 8.12477 20V4.25002C8.12328 3.54389 7.87275 2.86092 7.41729 2.32131C6.96182 1.78171 6.33062 1.42006 5.63477 1.30002C5.70929 1.26345 5.79184 1.24626 5.87477 1.25002H18.8748C20.1342 1.25108 21.3418 1.75186 22.2324 2.64243C23.1229 3.533 23.6237 4.74057 23.6248 6.00002Z" fill="#FECC0E" />
                                        <path d="M15.875 8.75H12.875C12.6761 8.75 12.4853 8.67098 12.3447 8.53033C12.204 8.38968 12.125 8.19891 12.125 8C12.125 7.80109 12.204 7.61032 12.3447 7.46967C12.4853 7.32902 12.6761 7.25 12.875 7.25H15.875C16.0739 7.25 16.2647 7.32902 16.4053 7.46967C16.546 7.61032 16.625 7.80109 16.625 8C16.625 8.19891 16.546 8.38968 16.4053 8.53033C16.2647 8.67098 16.0739 8.75 15.875 8.75Z" fill="white" />
                                        <path d="M18.875 11.75H12.875C12.6761 11.75 12.4853 11.671 12.3447 11.5303C12.204 11.3897 12.125 11.1989 12.125 11C12.125 10.8011 12.204 10.6103 12.3447 10.4697C12.4853 10.329 12.6761 10.25 12.875 10.25H18.875C19.0739 10.25 19.2647 10.329 19.4053 10.4697C19.546 10.6103 19.625 10.8011 19.625 11C19.625 11.1989 19.546 11.3897 19.4053 11.5303C19.2647 11.671 19.0739 11.75 18.875 11.75Z" fill="white" />
                                        <path d="M18.875 14.75H12.875C12.6761 14.75 12.4853 14.671 12.3447 14.5303C12.204 14.3897 12.125 14.1989 12.125 14C12.125 13.8011 12.204 13.6103 12.3447 13.4697C12.4853 13.329 12.6761 13.25 12.875 13.25H18.875C19.0739 13.25 19.2647 13.329 19.4053 13.4697C19.546 13.6103 19.625 13.8011 19.625 14C19.625 14.1989 19.546 14.3897 19.4053 14.5303C19.2647 14.671 19.0739 14.75 18.875 14.75Z" fill="white" />
                                        <path d="M18.875 17.75H12.875C12.6761 17.75 12.4853 17.671 12.3447 17.5303C12.204 17.3897 12.125 17.1989 12.125 17C12.125 16.8011 12.204 16.6103 12.3447 16.4697C12.4853 16.329 12.6761 16.25 12.875 16.25H18.875C19.0739 16.25 19.2647 16.329 19.4053 16.4697C19.546 16.6103 19.625 16.8011 19.625 17C19.625 17.1989 19.546 17.3897 19.4053 17.5303C19.2647 17.671 19.0739 17.75 18.875 17.75Z" fill="white" />
                                    </svg>
                                </div>
                                Stake
                            </Link>
                        </li>
                        <li>
                            <Link to="/trade">
                                <div className="icon-box bg_color_4">
                                    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4355_16088)">
                                            <path d="M20.3247 10.3362C20.1985 10.1213 19.9678 9.98934 19.7186 9.98934H14.5737L15.2515 0.754543C15.2756 0.425527 15.0678 0.12398 14.7518 0.0294335C14.4357 -0.0653009 13.7855 -0.288481 13.625 -0.000246391L6.91695 12.9651C6.79568 13.1828 6.79878 13.4485 6.92506 13.6634C7.05134 13.8783 7.28201 14.0102 7.53124 14.0102H12.6762L11.625 23.9998H12.625C12.9436 24.0952 13.1548 23.9244 13.3139 23.6386L20.3329 11.0346C20.4541 10.8168 20.451 10.5511 20.3247 10.3362Z" fill="#C8A52A" />
                                            <path d="M19.3247 10.3364C19.1985 10.1215 18.9678 9.98956 18.7186 9.98956H13.5737L14.2515 0.754767C14.2756 0.425751 14.0678 0.124204 13.7518 0.0296573C13.4357 -0.0650771 13.0965 0.0729229 12.936 0.361157L5.91695 12.9653C5.79568 13.1831 5.79878 13.4487 5.92506 13.6636C6.05134 13.8785 6.28201 14.0105 6.53124 14.0105H11.6762L10.9983 23.2453C10.9742 23.5743 11.182 23.8758 11.4981 23.9704C11.8167 24.0658 12.1548 23.9246 12.3139 23.6389L19.3329 11.0348C19.4541 10.817 19.451 10.5513 19.3247 10.3364Z" fill="#F2C31C" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4355_16088">
                                                <rect width={24} height={24} fill="white" transform="translate(0.625)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                Trade
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='mt-3'>
              <div className='d-flex justify-content-center align-items-center flex-column'>
              <div class="mt-3">
              {isMining ?
                 <div className='mt-3'>
                    <h4 className='text-center'>Please Wait</h4>
                    <img src="assets/images/mining.gif" style={{width:"150px",height:"150px"}}alt="image" />
                 </div>

                 :

                 <div>
                 {isMineStatus ? 
                    <div className='mt-3'>
                    <h4 className='text-center'>Today Mining is Done</h4>
                    <img src="assets/images/done.png" style={{width:"150px",height:"150px"}}alt="image" />
                 </div>
                 :
                 <div className='mt-3'>
                    <h4 className='text-center'>Press Here For Mining</h4>
                    
                    <a href="javascript:void(0)" onClick={startMining}><img src="assets/images/MiningButton.png" style={{width:"150px",height:"150px"}}alt="image" /></a>
                 </div>
                 }
                 </div>
              }
              </div>
              </div>
            </div>
            {/* footer */}
            <div className="bottom-navigation-bar">
                <div className="tf-container">
                    <ul className="tf-navigation-bar">
                        <li className="active"><Link className="fw_6 d-flex justify-content-center align-items-center flex-column" to="/"><i className="icon-home2" /> Home</Link> </li>
                        <li><Link className="fw_4 d-flex justify-content-center align-items-center flex-column" to="/history"><i className="icon-history" /> History</Link> </li>
                        <li><Link className="fw_4 d-flex justify-content-center align-items-center flex-column"  to="/qr-code"><i className="icon-scan-qr-code" /> </Link> </li>
                        <li><Link className="fw_4 d-flex justify-content-center align-items-center flex-column" to="/rewards"><svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.25" cy={12} r="9.5" stroke="#717171" />
                            <path d="M17.033 11.5318C17.2298 11.3316 17.2993 11.0377 17.2144 10.7646C17.1293 10.4914 16.9076 10.2964 16.6353 10.255L14.214 9.88781C14.1109 9.87213 14.0218 9.80462 13.9758 9.70702L12.8933 7.41717C12.7717 7.15989 12.525 7 12.2501 7C11.9754 7 11.7287 7.15989 11.6071 7.41717L10.5244 9.70723C10.4784 9.80483 10.3891 9.87234 10.286 9.88802L7.86469 10.2552C7.59257 10.2964 7.3707 10.4916 7.2856 10.7648C7.2007 11.038 7.27018 11.3318 7.46702 11.532L9.2189 13.3144C9.29359 13.3905 9.32783 13.5 9.31021 13.607L8.89692 16.1239C8.86027 16.3454 8.91594 16.5609 9.0533 16.7308C9.26676 16.9956 9.6394 17.0763 9.93735 16.9128L12.1027 15.7244C12.1932 15.6749 12.3072 15.6753 12.3975 15.7244L14.563 16.9128C14.6684 16.9707 14.7807 17 14.8966 17C15.1083 17 15.3089 16.9018 15.4469 16.7308C15.5845 16.5609 15.6399 16.345 15.6033 16.1239L15.1898 13.607C15.1722 13.4998 15.2064 13.3905 15.2811 13.3144L17.033 11.5318Z" stroke="#717171" strokeWidth="1.25" />
                        </svg>
                            Rewards</Link> </li>
                        <li><Link className="fw_4 d-flex justify-content-center align-items-center flex-column" to="/profile"><i className="icon-user-outline" /> Profile</Link> </li>
                    </ul>
                    {/* <span class="line"></span> */}
                </div>
            </div>
            {/* <Footer/> */}
            
            <div className="modal fade" id="modalhome1">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="heading">
                            <h4 className="fw_6 text-center">
                                “Alipay” Would Like To
                                Send You Notifications
                            </h4>
                            <p className="fw_4 mt-2 text-center">Notifications may include alerts, sounds, and icon badges. These can be configure in Settings.</p>
                        </div>
                        <div className="bottom">
                            <Link to="#" className="secondary_color btn-hide-modal" data-dismiss="modal" aria-label="Close">Don’t Allow</Link>
                            <Link to="#" className="primary_color btn-hide-modal" data-toggle="modal" data-target="#modalhome2" data-dismiss="modal" aria-label="Close"> Allow</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalhome2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="heading">
                            <h2>Privacy</h2>
                            <p className="mt-1 fw_3">
                                A mobile app privacy policy is a legal statement that must be clear, conspicuous, and consented to by all users. It must disclose how a mobile app gathers, stores, and uses the personally identifiable information it collects from its users.
                                A mobile privacy app is developed and presented to users so that mobile app developers stay compliant with state, federal, and international laws. As a result, they fulfill the legal requirement to safeguard user privacy while protecting the company itself from legal challenges.
                            </p>
                            <h2 className="mt-3">Authorized Users</h2>
                            <p className="fw-3">
                                A mobile app privacy policy is a legal statement that must be clear, conspicuous, and consented to by all users. It must disclose how a mobile app gathers, stores, and uses the personally identifiable information it collects from its users.
                            </p>
                            <div className="group-cb mt-3 align-items-center">
                                <div className="cb">
                                    <input type="checkbox" className="tf-checkbox st1" defaultChecked />
                                </div>
                                <span className="fw_3">I agree to the Term of sevice and Privacy policyy</span>
                            </div>
                        </div>
                        <div className="bottom mt-5">
                            <Link to="#" className="tf-btn accent large" data-dismiss="modal">I Accept</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tf-panel up">
                <div className="panel-box panel-up panel-noti">
                    <div className="header is-fixed">
                        <div className="tf-container">
                            <div className="tf-statusbar d-flex justify-content-center align-items-center">
                                <Link to="#" className="clear-panel"> <i className="icon-left" /> </Link>
                                <h3>Notification</h3>
                            </div>
                        </div>
                    </div>
                    <div id="app-wrap" className="style1">
                        <div className="tf-container">
                            <div className="tf-tab mt-3">
                                <div className="swiper-container tes-noti">
                                    <ul className="swiper-wrapper menu-tabs">
                                        <li className="swiper-slide nav-tab active "><Link to="#">All Notification</Link></li>
                                        <li className=" swiper-slide nav-tab "><Link to="#">Remind</Link></li>
                                        <li className="swiper-slide nav-tab"><Link to="#"> Transaction</Link></li>
                                        <li className="swiper-slide nav-tab "><Link to="#">Promotion</Link></li>
                                    </ul>
                                </div>
                                <div className="content-tab mt-5">
                                    <div className="noti-box">
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-4">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Restaurant</h3>
                                                    <span className="fw_6 on_surface_color">5 Min Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Shopping</h3>
                                                    <span className="secondary_color fw_4">25 Min Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Shopping</h3>
                                                    <span className="fw_6 on_surface_color">1 Hour  Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-7">
                                                <svg width={16} height={16} viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.10792 2.89151V3.71843H2.76196V2.89151C2.76196 2.64696 2.9602 2.44873 3.20474 2.44873H4.66513C4.90968 2.44873 5.10792 2.64696 5.10792 2.89151Z" fill="#6D6D6D" />
                                                    <path d="M21.9048 2.89151V3.71843H19.5588V2.89151C19.5588 2.64696 19.7571 2.44873 20.0016 2.44873H21.462C21.7066 2.44873 21.9048 2.64696 21.9048 2.89151Z" fill="#6D6D6D" />
                                                    <path d="M15.7525 5.0683H8.91426C8.63245 5.0683 8.40393 4.83978 8.40393 4.55797V1.85661C8.40393 1.15315 8.97632 0.580811 9.67973 0.580811H14.987C15.6905 0.580811 16.2628 1.1532 16.2628 1.85661V4.55797C16.2628 4.83978 16.0343 5.0683 15.7525 5.0683ZM9.42454 4.04764H15.2421V1.85661C15.2421 1.71584 15.1276 1.60147 14.987 1.60147H9.67973C9.53906 1.60147 9.42459 1.71584 9.42459 1.85661V4.04764H9.42454Z" fill="#6D6D6D" />
                                                    <path d="M5.39564 19.5364V20.9087C5.39564 21.1906 5.16718 21.4191 4.88532 21.4191H2.62739C2.34553 21.4191 2.11707 21.1906 2.11707 20.9087V19.5364H5.39564Z" fill="#EA4E4E" />
                                                    <path d="M22.5498 19.5364V20.9087C22.5498 21.1906 22.3213 21.4191 22.0395 21.4191H19.7816C19.4997 21.4191 19.2712 21.1906 19.2712 20.9087V19.5364H22.5498Z" fill="#EA4E4E" />
                                                    <path d="M24.326 4.50845V19.2502C24.326 19.8139 23.869 20.2709 23.3053 20.2709H1.36148C0.797805 20.2709 0.34082 19.8139 0.34082 19.2502V4.50845C0.34082 3.94478 0.797758 3.48779 1.36148 3.48779H23.3053C23.869 3.48779 24.326 3.94478 24.326 4.50845Z" fill="#F96464" />
                                                    <g opacity="0.4">
                                                        <path d="M1.91377 7.6168C1.63191 7.6168 1.40344 7.38834 1.40344 7.10648V5.27498C1.40344 4.99312 1.63191 4.76465 1.91377 4.76465C2.19563 4.76465 2.4241 4.99312 2.4241 5.27498V7.10648C2.4241 7.38834 2.19563 7.6168 1.91377 7.6168Z" fill="white" />
                                                    </g>
                                                    <g opacity="0.08">
                                                        <path d="M24.326 4.50943V19.2501C24.326 19.814 23.8693 20.2707 23.3054 20.2707H21.5116C22.0755 20.2707 22.5323 19.814 22.5323 19.2501V4.50943C22.5323 3.94552 22.0755 3.48877 21.5116 3.48877H23.3054C23.8693 3.48877 24.326 3.94552 24.326 4.50943Z" fill="black" />
                                                    </g>
                                                    <path d="M12.3335 17.3581C15.3597 17.3581 17.8129 14.9048 17.8129 11.8786C17.8129 8.8524 15.3597 6.39917 12.3335 6.39917C9.30724 6.39917 6.854 8.8524 6.854 11.8786C6.854 14.9048 9.30724 17.3581 12.3335 17.3581Z" fill="#F2F0F0" />
                                                    <path d="M16.0116 11.3237V12.4362C16.0116 12.718 15.7831 12.9465 15.5012 12.9465H13.3987V15.0464C13.3987 15.3283 13.1702 15.5568 12.8884 15.5568H11.7758C11.494 15.5568 11.2655 15.3283 11.2655 15.0464V12.9465H9.1656C8.88374 12.9465 8.65527 12.718 8.65527 12.4362V11.3237C8.65527 11.0418 8.88374 10.8133 9.1656 10.8133H11.2656V8.71077C11.2656 8.42891 11.494 8.20044 11.7759 8.20044H12.8884C13.1703 8.20044 13.3987 8.42891 13.3987 8.71077V10.8133H15.5013C15.7831 10.8133 16.0116 11.0418 16.0116 11.3237Z" fill="#F96464" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Medical</h3>
                                                    <span className="secondary_color fw_4">15 Hour Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-6">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Travel</h3>
                                                    <span className="secondary_color fw_4">1 Day Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Shopping</h3>
                                                    <span className="fw_6 on_surface_color">9/1/2023</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Shopping</h3>
                                                    <span className="secondary_color fw_4">7/1/2023</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-6">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Travel</h3>
                                                    <span className="secondary_color fw_4">1 Day Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 2 */}
                                    <div className="noti-box">
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-4">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Restaurant</h3>
                                                    <span className="fw_6 on_surface_color">5 Min Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Shopping</h3>
                                                    <span className="secondary_color fw_4">25 Min Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Shopping</h3>
                                                    <span className="fw_6 on_surface_color">1 Hour  Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-7">
                                                <svg width={16} height={16} viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.10792 2.89151V3.71843H2.76196V2.89151C2.76196 2.64696 2.9602 2.44873 3.20474 2.44873H4.66513C4.90968 2.44873 5.10792 2.64696 5.10792 2.89151Z" fill="#6D6D6D" />
                                                    <path d="M21.9048 2.89151V3.71843H19.5588V2.89151C19.5588 2.64696 19.7571 2.44873 20.0016 2.44873H21.462C21.7066 2.44873 21.9048 2.64696 21.9048 2.89151Z" fill="#6D6D6D" />
                                                    <path d="M15.7525 5.0683H8.91426C8.63245 5.0683 8.40393 4.83978 8.40393 4.55797V1.85661C8.40393 1.15315 8.97632 0.580811 9.67973 0.580811H14.987C15.6905 0.580811 16.2628 1.1532 16.2628 1.85661V4.55797C16.2628 4.83978 16.0343 5.0683 15.7525 5.0683ZM9.42454 4.04764H15.2421V1.85661C15.2421 1.71584 15.1276 1.60147 14.987 1.60147H9.67973C9.53906 1.60147 9.42459 1.71584 9.42459 1.85661V4.04764H9.42454Z" fill="#6D6D6D" />
                                                    <path d="M5.39564 19.5364V20.9087C5.39564 21.1906 5.16718 21.4191 4.88532 21.4191H2.62739C2.34553 21.4191 2.11707 21.1906 2.11707 20.9087V19.5364H5.39564Z" fill="#EA4E4E" />
                                                    <path d="M22.5498 19.5364V20.9087C22.5498 21.1906 22.3213 21.4191 22.0395 21.4191H19.7816C19.4997 21.4191 19.2712 21.1906 19.2712 20.9087V19.5364H22.5498Z" fill="#EA4E4E" />
                                                    <path d="M24.326 4.50845V19.2502C24.326 19.8139 23.869 20.2709 23.3053 20.2709H1.36148C0.797805 20.2709 0.34082 19.8139 0.34082 19.2502V4.50845C0.34082 3.94478 0.797758 3.48779 1.36148 3.48779H23.3053C23.869 3.48779 24.326 3.94478 24.326 4.50845Z" fill="#F96464" />
                                                    <g opacity="0.4">
                                                        <path d="M1.91377 7.6168C1.63191 7.6168 1.40344 7.38834 1.40344 7.10648V5.27498C1.40344 4.99312 1.63191 4.76465 1.91377 4.76465C2.19563 4.76465 2.4241 4.99312 2.4241 5.27498V7.10648C2.4241 7.38834 2.19563 7.6168 1.91377 7.6168Z" fill="white" />
                                                    </g>
                                                    <g opacity="0.08">
                                                        <path d="M24.326 4.50943V19.2501C24.326 19.814 23.8693 20.2707 23.3054 20.2707H21.5116C22.0755 20.2707 22.5323 19.814 22.5323 19.2501V4.50943C22.5323 3.94552 22.0755 3.48877 21.5116 3.48877H23.3054C23.8693 3.48877 24.326 3.94552 24.326 4.50943Z" fill="black" />
                                                    </g>
                                                    <path d="M12.3335 17.3581C15.3597 17.3581 17.8129 14.9048 17.8129 11.8786C17.8129 8.8524 15.3597 6.39917 12.3335 6.39917C9.30724 6.39917 6.854 8.8524 6.854 11.8786C6.854 14.9048 9.30724 17.3581 12.3335 17.3581Z" fill="#F2F0F0" />
                                                    <path d="M16.0116 11.3237V12.4362C16.0116 12.718 15.7831 12.9465 15.5012 12.9465H13.3987V15.0464C13.3987 15.3283 13.1702 15.5568 12.8884 15.5568H11.7758C11.494 15.5568 11.2655 15.3283 11.2655 15.0464V12.9465H9.1656C8.88374 12.9465 8.65527 12.718 8.65527 12.4362V11.3237C8.65527 11.0418 8.88374 10.8133 9.1656 10.8133H11.2656V8.71077C11.2656 8.42891 11.494 8.20044 11.7759 8.20044H12.8884C13.1703 8.20044 13.3987 8.42891 13.3987 8.71077V10.8133H15.5013C15.7831 10.8133 16.0116 11.0418 16.0116 11.3237Z" fill="#F96464" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Medical</h3>
                                                    <span className="secondary_color fw_4">15 Hour Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-6">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Travel</h3>
                                                    <span className="secondary_color fw_4">1 Day Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Shopping</h3>
                                                    <span className="fw_6 on_surface_color">9/1/2023</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Shopping</h3>
                                                    <span className="secondary_color fw_4">7/1/2023</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-6">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Travel</h3>
                                                    <span className="secondary_color fw_4">1 Day Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 3 */}
                                    <div className="noti-box">
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-4">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Restaurant</h3>
                                                    <span className="fw_6 on_surface_color">5 Min Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Shopping</h3>
                                                    <span className="secondary_color fw_4">25 Min Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Shopping</h3>
                                                    <span className="fw_6 on_surface_color">1 Hour  Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-7">
                                                <svg width={16} height={16} viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.10792 2.89151V3.71843H2.76196V2.89151C2.76196 2.64696 2.9602 2.44873 3.20474 2.44873H4.66513C4.90968 2.44873 5.10792 2.64696 5.10792 2.89151Z" fill="#6D6D6D" />
                                                    <path d="M21.9048 2.89151V3.71843H19.5588V2.89151C19.5588 2.64696 19.7571 2.44873 20.0016 2.44873H21.462C21.7066 2.44873 21.9048 2.64696 21.9048 2.89151Z" fill="#6D6D6D" />
                                                    <path d="M15.7525 5.0683H8.91426C8.63245 5.0683 8.40393 4.83978 8.40393 4.55797V1.85661C8.40393 1.15315 8.97632 0.580811 9.67973 0.580811H14.987C15.6905 0.580811 16.2628 1.1532 16.2628 1.85661V4.55797C16.2628 4.83978 16.0343 5.0683 15.7525 5.0683ZM9.42454 4.04764H15.2421V1.85661C15.2421 1.71584 15.1276 1.60147 14.987 1.60147H9.67973C9.53906 1.60147 9.42459 1.71584 9.42459 1.85661V4.04764H9.42454Z" fill="#6D6D6D" />
                                                    <path d="M5.39564 19.5364V20.9087C5.39564 21.1906 5.16718 21.4191 4.88532 21.4191H2.62739C2.34553 21.4191 2.11707 21.1906 2.11707 20.9087V19.5364H5.39564Z" fill="#EA4E4E" />
                                                    <path d="M22.5498 19.5364V20.9087C22.5498 21.1906 22.3213 21.4191 22.0395 21.4191H19.7816C19.4997 21.4191 19.2712 21.1906 19.2712 20.9087V19.5364H22.5498Z" fill="#EA4E4E" />
                                                    <path d="M24.326 4.50845V19.2502C24.326 19.8139 23.869 20.2709 23.3053 20.2709H1.36148C0.797805 20.2709 0.34082 19.8139 0.34082 19.2502V4.50845C0.34082 3.94478 0.797758 3.48779 1.36148 3.48779H23.3053C23.869 3.48779 24.326 3.94478 24.326 4.50845Z" fill="#F96464" />
                                                    <g opacity="0.4">
                                                        <path d="M1.91377 7.6168C1.63191 7.6168 1.40344 7.38834 1.40344 7.10648V5.27498C1.40344 4.99312 1.63191 4.76465 1.91377 4.76465C2.19563 4.76465 2.4241 4.99312 2.4241 5.27498V7.10648C2.4241 7.38834 2.19563 7.6168 1.91377 7.6168Z" fill="white" />
                                                    </g>
                                                    <g opacity="0.08">
                                                        <path d="M24.326 4.50943V19.2501C24.326 19.814 23.8693 20.2707 23.3054 20.2707H21.5116C22.0755 20.2707 22.5323 19.814 22.5323 19.2501V4.50943C22.5323 3.94552 22.0755 3.48877 21.5116 3.48877H23.3054C23.8693 3.48877 24.326 3.94552 24.326 4.50943Z" fill="black" />
                                                    </g>
                                                    <path d="M12.3335 17.3581C15.3597 17.3581 17.8129 14.9048 17.8129 11.8786C17.8129 8.8524 15.3597 6.39917 12.3335 6.39917C9.30724 6.39917 6.854 8.8524 6.854 11.8786C6.854 14.9048 9.30724 17.3581 12.3335 17.3581Z" fill="#F2F0F0" />
                                                    <path d="M16.0116 11.3237V12.4362C16.0116 12.718 15.7831 12.9465 15.5012 12.9465H13.3987V15.0464C13.3987 15.3283 13.1702 15.5568 12.8884 15.5568H11.7758C11.494 15.5568 11.2655 15.3283 11.2655 15.0464V12.9465H9.1656C8.88374 12.9465 8.65527 12.718 8.65527 12.4362V11.3237C8.65527 11.0418 8.88374 10.8133 9.1656 10.8133H11.2656V8.71077C11.2656 8.42891 11.494 8.20044 11.7759 8.20044H12.8884C13.1703 8.20044 13.3987 8.42891 13.3987 8.71077V10.8133H15.5013C15.7831 10.8133 16.0116 11.0418 16.0116 11.3237Z" fill="#F96464" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Medical</h3>
                                                    <span className="secondary_color fw_4">15 Hour Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-6">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Travel</h3>
                                                    <span className="secondary_color fw_4">1 Day Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Shopping</h3>
                                                    <span className="fw_6 on_surface_color">9/1/2023</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Shopping</h3>
                                                    <span className="secondary_color fw_4">7/1/2023</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-6">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Travel</h3>
                                                    <span className="secondary_color fw_4">1 Day Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 4 */}
                                    <div className="noti-box">
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-4">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Restaurant</h3>
                                                    <span className="fw_6 on_surface_color">5 Min Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Shopping</h3>
                                                    <span className="secondary_color fw_4">25 Min Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Shopping</h3>
                                                    <span className="fw_6 on_surface_color">1 Hour  Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-7">
                                                <svg width={16} height={16} viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.10792 2.89151V3.71843H2.76196V2.89151C2.76196 2.64696 2.9602 2.44873 3.20474 2.44873H4.66513C4.90968 2.44873 5.10792 2.64696 5.10792 2.89151Z" fill="#6D6D6D" />
                                                    <path d="M21.9048 2.89151V3.71843H19.5588V2.89151C19.5588 2.64696 19.7571 2.44873 20.0016 2.44873H21.462C21.7066 2.44873 21.9048 2.64696 21.9048 2.89151Z" fill="#6D6D6D" />
                                                    <path d="M15.7525 5.0683H8.91426C8.63245 5.0683 8.40393 4.83978 8.40393 4.55797V1.85661C8.40393 1.15315 8.97632 0.580811 9.67973 0.580811H14.987C15.6905 0.580811 16.2628 1.1532 16.2628 1.85661V4.55797C16.2628 4.83978 16.0343 5.0683 15.7525 5.0683ZM9.42454 4.04764H15.2421V1.85661C15.2421 1.71584 15.1276 1.60147 14.987 1.60147H9.67973C9.53906 1.60147 9.42459 1.71584 9.42459 1.85661V4.04764H9.42454Z" fill="#6D6D6D" />
                                                    <path d="M5.39564 19.5364V20.9087C5.39564 21.1906 5.16718 21.4191 4.88532 21.4191H2.62739C2.34553 21.4191 2.11707 21.1906 2.11707 20.9087V19.5364H5.39564Z" fill="#EA4E4E" />
                                                    <path d="M22.5498 19.5364V20.9087C22.5498 21.1906 22.3213 21.4191 22.0395 21.4191H19.7816C19.4997 21.4191 19.2712 21.1906 19.2712 20.9087V19.5364H22.5498Z" fill="#EA4E4E" />
                                                    <path d="M24.326 4.50845V19.2502C24.326 19.8139 23.869 20.2709 23.3053 20.2709H1.36148C0.797805 20.2709 0.34082 19.8139 0.34082 19.2502V4.50845C0.34082 3.94478 0.797758 3.48779 1.36148 3.48779H23.3053C23.869 3.48779 24.326 3.94478 24.326 4.50845Z" fill="#F96464" />
                                                    <g opacity="0.4">
                                                        <path d="M1.91377 7.6168C1.63191 7.6168 1.40344 7.38834 1.40344 7.10648V5.27498C1.40344 4.99312 1.63191 4.76465 1.91377 4.76465C2.19563 4.76465 2.4241 4.99312 2.4241 5.27498V7.10648C2.4241 7.38834 2.19563 7.6168 1.91377 7.6168Z" fill="white" />
                                                    </g>
                                                    <g opacity="0.08">
                                                        <path d="M24.326 4.50943V19.2501C24.326 19.814 23.8693 20.2707 23.3054 20.2707H21.5116C22.0755 20.2707 22.5323 19.814 22.5323 19.2501V4.50943C22.5323 3.94552 22.0755 3.48877 21.5116 3.48877H23.3054C23.8693 3.48877 24.326 3.94552 24.326 4.50943Z" fill="black" />
                                                    </g>
                                                    <path d="M12.3335 17.3581C15.3597 17.3581 17.8129 14.9048 17.8129 11.8786C17.8129 8.8524 15.3597 6.39917 12.3335 6.39917C9.30724 6.39917 6.854 8.8524 6.854 11.8786C6.854 14.9048 9.30724 17.3581 12.3335 17.3581Z" fill="#F2F0F0" />
                                                    <path d="M16.0116 11.3237V12.4362C16.0116 12.718 15.7831 12.9465 15.5012 12.9465H13.3987V15.0464C13.3987 15.3283 13.1702 15.5568 12.8884 15.5568H11.7758C11.494 15.5568 11.2655 15.3283 11.2655 15.0464V12.9465H9.1656C8.88374 12.9465 8.65527 12.718 8.65527 12.4362V11.3237C8.65527 11.0418 8.88374 10.8133 9.1656 10.8133H11.2656V8.71077C11.2656 8.42891 11.494 8.20044 11.7759 8.20044H12.8884C13.1703 8.20044 13.3987 8.42891 13.3987 8.71077V10.8133H15.5013C15.7831 10.8133 16.0116 11.0418 16.0116 11.3237Z" fill="#F96464" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Medical</h3>
                                                    <span className="secondary_color fw_4">15 Hour Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-6">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Travel</h3>
                                                    <span className="secondary_color fw_4">1 Day Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_6">Shopping</h3>
                                                    <span className="fw_6 on_surface_color">9/1/2023</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="on_surface_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                    <i className="dot" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-3">
                                                <svg width={16} height={16} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.2838 7.48567C22.5636 -5.34697 15.3153 2.01241 6.99199 5.99402C4.62414 7.12675 1.38339 7.13603 0.529097 10.1115C-0.458746 13.5521 2.49213 15.7224 4.86696 17.5562C7.726 19.7638 7.32082 24.1061 11.9929 23.7198C12.874 23.647 13.9302 23.7352 14.4994 23.0587C16.8551 20.2588 17.5094 16.0487 20.5731 13.8156C22.7339 12.2406 24.6702 10.3686 24.2838 7.48567V7.48567Z" fill="url(#paint0_linear_4516_5327)" />
                                                    <path d="M11.1101 8.50233C10.8512 8.50233 10.6413 8.29243 10.6413 8.03358V4.51604C10.6413 3.36872 9.70791 2.4353 8.56059 2.4353C7.41328 2.4353 6.47986 3.36872 6.47986 4.51604V8.03358C6.47986 8.29243 6.27 8.50233 6.01111 8.50233C5.75222 8.50233 5.54236 8.29243 5.54236 8.03358V4.51604C5.54236 2.85179 6.89634 1.4978 8.56059 1.4978C10.2248 1.4978 11.5788 2.85179 11.5788 4.51604V8.03358C11.5788 8.29248 11.369 8.50233 11.1101 8.50233V8.50233Z" fill="#FFC498" />
                                                    <path d="M4.77612 20.4084H2.87707C2.74085 20.4084 2.63332 20.2927 2.64335 20.1568L3.59196 7.27699C3.60096 7.15455 3.70296 7.05981 3.82568 7.05981H5.72482L4.77612 20.4084Z" fill="#FF7300" />
                                                    <path d="M10.2019 1.98511C9.70002 2.02945 9.26681 2.20284 8.90198 2.46379C9.5587 2.57269 10.1121 2.99006 10.4085 3.56161C10.4256 3.56119 10.4425 3.56029 10.4596 3.56029C10.8392 3.56029 11.1951 3.663 11.5018 3.84131C11.3243 3.06712 10.8485 2.40586 10.2019 1.98511V1.98511Z" fill="#FFAA66" />
                                                    <path d="M13.3223 5.13473C14.475 5.29359 15.5495 5.49229 16.5249 5.72507C16.6042 5.74396 16.6598 5.81503 16.6598 5.8965V9.13706C16.6598 9.26034 16.5367 9.34537 16.4213 9.30168C15.46 8.93756 14.4044 8.66198 13.2743 8.50078C13.1872 8.48836 13.1222 8.41434 13.1222 8.3264V5.30939C13.1222 5.2027 13.2166 5.12015 13.3223 5.13473V5.13473Z" fill="#9393FF" />
                                                    <path d="M19.934 11.522C19.3646 10.9961 18.6909 10.5108 17.9281 10.086C17.8721 10.0548 17.837 9.99604 17.837 9.93201V6.37448C17.837 6.25486 17.9539 6.16978 18.0677 6.20672C20.528 7.0057 22.0239 8.06062 22.0239 9.21736L21.8832 11.1587L19.934 11.522Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3463L21.8386 14.0752L22.0238 14.2763V14.5922L21.9179 15.2644L22.0238 15.5222V16.0791C22.0238 17.1707 20.6922 18.5447 18.4754 19.6873C18.2885 19.7836 18.0657 19.6472 18.0657 19.4369V12.4824C18.0657 12.3314 18.1622 12.1974 18.3052 12.1486C20.6241 11.3602 22.0238 10.3364 22.0238 9.21753V13.3463Z" fill="#D8D8FF" />
                                                    <path d="M22.0238 14.592V15.522C21.4594 16.2172 20.5032 16.8463 19.4996 17.4355C19.4625 17.4575 19.4217 17.4678 19.3814 17.4678C19.3008 17.4678 19.2225 17.4266 19.1789 17.352C19.1133 17.2405 19.1508 17.0966 19.2624 17.0314C20.9536 16.0386 21.8133 15.2853 22.0238 14.592V14.592Z" fill="#9393FF" />
                                                    <path d="M22.0238 13.3462V14.2762C21.4589 14.9713 20.5032 15.6004 19.4996 16.1896C19.4625 16.2112 19.4217 16.2215 19.3814 16.2215C19.3008 16.2215 19.2225 16.1803 19.1789 16.1062C19.1133 15.9942 19.1508 15.8507 19.2624 15.7851C20.9536 14.7923 21.8133 14.0395 22.0238 13.3462Z" fill="#9393FF" />
                                                    <path d="M19.3813 15.2074C19.3025 15.2074 19.2256 15.1678 19.1813 15.0957C19.1136 14.9854 19.148 14.8411 19.2583 14.7734C19.5345 14.6037 19.7758 14.4484 19.996 14.2986C20.1032 14.2258 20.2489 14.2537 20.3216 14.3607C20.3944 14.4678 20.3666 14.6136 20.2596 14.6863C20.0333 14.8401 19.786 14.9993 19.5037 15.1727C19.4655 15.1962 19.4231 15.2074 19.3813 15.2074V15.2074Z" fill="#9393FF" />
                                                    <path d="M19.3812 13.4436C19.2918 13.4436 19.2064 13.3922 19.1674 13.3054C19.1142 13.1874 19.1668 13.0486 19.2848 12.9955C19.6547 12.8289 19.9883 12.6536 20.2766 12.4743C20.3866 12.406 20.5311 12.4397 20.5994 12.5495C20.6677 12.6595 20.634 12.804 20.5242 12.8724C20.2183 13.0626 19.8661 13.2478 19.4773 13.4229C19.4461 13.4369 19.4134 13.4436 19.3812 13.4436V13.4436Z" fill="#FF9393" />
                                                    <path d="M18.0681 6.20699C17.9542 6.17001 17.837 6.2549 17.837 6.37462V9.93215C17.837 9.99623 17.8721 10.055 17.9281 10.0862C18.6779 10.5038 19.3408 10.9801 19.904 11.4957C19.4371 11.7274 18.9017 11.946 18.3048 12.149C18.1621 12.1976 18.0658 12.3317 18.0658 12.4826V19.437C18.0658 19.6473 18.2886 19.7838 18.4756 19.6874C20.6923 18.5446 22.0239 17.1709 22.0239 16.0793V9.21749C22.0239 8.0608 20.5282 7.00593 18.0681 6.20699Z" fill="url(#paint1_linear_4516_5327)" />
                                                    <path d="M16.1433 20.4084H4.77616C4.63975 20.4084 4.53241 20.2926 4.54225 20.1567L5.491 7.27685C5.49991 7.1545 5.6021 7.05981 5.72491 7.05981H15.1946C15.3174 7.05981 15.4191 7.1545 15.4285 7.27685L16.3768 20.1567C16.3871 20.2926 16.2793 20.4084 16.1433 20.4084Z" fill="#FFAA66" />
                                                    <path d="M11.0447 10.5326C10.9228 10.6545 10.7608 10.7216 10.5884 10.7216C10.4161 10.7216 10.2541 10.6545 10.1322 10.5326C10.0104 10.4107 9.94324 10.2487 9.94324 10.0763C9.94324 9.90399 10.0104 9.74199 10.1322 9.62011C10.3838 9.36849 10.7932 9.36854 11.0447 9.62011L11.2104 9.78586L11.5419 9.45441L11.3761 9.28866C11.2188 9.13135 11.0257 9.03188 10.8228 8.98847V8.53955H10.3541V8.98852C10.1512 9.03188 9.95805 9.13139 9.80074 9.28866C9.59036 9.49908 9.47449 9.77883 9.47449 10.0763C9.47449 10.3739 9.59036 10.6537 9.80074 10.864C9.95472 11.018 10.146 11.1211 10.3541 11.1655V11.6131H10.8228V11.1655C11.0309 11.1211 11.2221 11.018 11.3761 10.864L11.5419 10.6983L11.2104 10.3668L11.0447 10.5326Z" fill="white" />
                                                    <path d="M7.44153 7.05981H8.37903V8.87763C8.37903 9.13638 8.16903 9.34638 7.91028 9.34638C7.65153 9.34638 7.44153 9.13638 7.44153 8.87763V7.05981Z" fill="#FF9C33" />
                                                    <path d="M13.4781 7.05981V8.87763C13.4781 9.13638 13.2681 9.34638 13.0094 9.34638C12.7502 9.34638 12.5406 9.13638 12.5406 8.87763V7.05981H13.4781Z" fill="#FF9C33" />
                                                    <path d="M10.9984 13.5775C12.884 13.4304 14.6278 13.183 16.1577 12.8558C16.3769 12.8089 16.5833 12.9764 16.5833 13.2005V20.4353C16.5833 20.5874 16.4858 20.7222 16.3414 20.7701C14.7835 21.2871 12.9957 21.6755 11.0566 21.8444C10.8508 21.8623 10.6738 21.6995 10.6738 21.4929V13.9288C10.6738 13.745 10.8151 13.5918 10.9984 13.5775V13.5775Z" fill="#D8D8FF" />
                                                    <path d="M15.4929 20.1811C15.3913 20.1811 15.2977 20.1146 15.2679 20.0122C15.2318 19.8879 15.3032 19.7578 15.4274 19.7216C15.4987 19.7009 15.5694 19.6798 15.6398 19.6586C15.7636 19.621 15.8946 19.6911 15.9321 19.815C15.9696 19.9389 15.8995 20.0697 15.7756 20.1072C15.7036 20.129 15.6313 20.1505 15.5584 20.1717C15.5366 20.1781 15.5146 20.1811 15.4929 20.1811V20.1811Z" fill="#8282FF" />
                                                    <path d="M15.4929 19.4651C15.3913 19.4651 15.2977 19.3986 15.2679 19.2961C15.2318 19.1719 15.3032 19.0418 15.4274 19.0056C15.4987 18.9848 15.5694 18.9638 15.6398 18.9425C15.7636 18.9049 15.8946 18.9751 15.9321 19.0989C15.9696 19.2228 15.8995 19.3537 15.7756 19.3912C15.7036 19.413 15.6313 19.4344 15.5584 19.4556C15.5366 19.462 15.5146 19.4651 15.4929 19.4651V19.4651Z" fill="#8282FF" />
                                                    <path d="M16.0011 15.6087L14.8434 14.5739C14.8277 14.5598 14.8076 14.5527 14.7878 14.5541L14.2639 14.5906C14.2315 14.5929 14.2048 14.6166 14.1978 14.6498C14.1563 14.846 13.9983 14.997 13.7931 15.013C13.586 15.0292 13.3922 14.9033 13.3049 14.713C13.29 14.6807 13.2572 14.6607 13.2233 14.6631L12.6622 14.7021C12.6407 14.7036 12.6207 14.714 12.6071 14.7308L11.5495 16.0345C11.5196 16.0715 11.5257 16.1262 11.5632 16.1566L12.2405 16.7049C12.2768 16.7343 12.3301 16.7283 12.359 16.6915L12.7033 16.2534L12.643 18.6497C12.6416 18.7057 12.6911 18.7494 12.7465 18.7411L15.0027 18.4026C15.0469 18.396 15.0795 18.3579 15.0794 18.3132L15.074 16.0106L15.4573 16.3438C15.4903 16.3725 15.5408 16.3668 15.5666 16.3314L16.0137 15.7194C16.038 15.6862 16.0324 15.6366 16.0011 15.6087V15.6087Z" fill="#FFAA66" />
                                                    <path d="M12.9243 20.3039C13.5523 20.3039 14.0615 19.7948 14.0615 19.1667C14.0615 18.5387 13.5523 18.0295 12.9243 18.0295C12.2962 18.0295 11.7871 18.5387 11.7871 19.1667C11.7871 19.7948 12.2962 20.3039 12.9243 20.3039Z" fill="#FF6C6C" />
                                                    <path d="M12.7735 19.6973C12.7114 19.6973 12.6518 19.6726 12.6078 19.6287L12.3171 19.3379C12.2255 19.2464 12.2255 19.098 12.3171 19.0065C12.4086 18.915 12.5571 18.915 12.6485 19.0065L12.7735 19.1315L13.2002 18.7049C13.2917 18.6134 13.4402 18.6134 13.5316 18.7049C13.6232 18.7964 13.6232 18.9448 13.5316 19.0363L12.9392 19.6287C12.8953 19.6726 12.8357 19.6973 12.7735 19.6973V19.6973Z" fill="white" />
                                                    <path d="M14.3828 16.2914C14.2533 16.2914 14.1484 16.1864 14.1484 16.057V15.9878C14.1484 15.8584 14.2533 15.7534 14.3828 15.7534C14.5123 15.7534 14.6172 15.8584 14.6172 15.9878V16.057C14.6172 16.1864 14.5122 16.2914 14.3828 16.2914Z" fill="#FFD5BA" />
                                                    <path d="M13.0091 8.50233C12.7502 8.50233 12.5404 8.29243 12.5404 8.03358V4.51604C12.5404 3.36872 11.607 2.4353 10.4596 2.4353C9.31233 2.4353 8.37891 3.36872 8.37891 4.51604V8.03358C8.37891 8.29243 8.16905 8.50233 7.91016 8.50233C7.65127 8.50233 7.44141 8.29243 7.44141 8.03358V4.51604C7.44141 2.85179 8.79539 1.4978 10.4596 1.4978C12.1239 1.4978 13.4779 2.85179 13.4779 4.51604V8.03358C13.4779 8.29248 13.268 8.50233 13.0091 8.50233Z" fill="#FFD5BA" />
                                                    <path d="M8.8133 19.1614H6.17639C6.04697 19.1614 5.94202 19.0564 5.94202 18.927C5.94202 18.7976 6.04697 18.6926 6.17639 18.6926H8.8133C8.94272 18.6926 9.04767 18.7976 9.04767 18.927C9.04767 19.0564 8.94272 19.1614 8.8133 19.1614Z" fill="#FFD5BA" />
                                                    <path d="M8.86591 18.2642H6.229C6.09958 18.2642 5.99463 18.1592 5.99463 18.0298C5.99463 17.9004 6.09958 17.7954 6.229 17.7954H8.86591C8.99533 17.7954 9.10028 17.9004 9.10028 18.0298C9.10028 18.1592 8.99533 18.2642 8.86591 18.2642Z" fill="#FFD5BA" />
                                                    <path d="M7.0261 17.3667H6.28149C6.15207 17.3667 6.04712 17.2617 6.04712 17.1323C6.04712 17.0029 6.15207 16.8979 6.28149 16.8979H7.0261C7.15553 16.8979 7.26048 17.0029 7.26048 17.1323C7.26048 17.2617 7.15553 17.3667 7.0261 17.3667Z" fill="#FFD5BA" />
                                                    <path d="M16.525 5.72504C15.5939 5.5028 14.5715 5.31202 13.478 5.15696V4.51604C13.478 2.85179 12.124 1.4978 10.4597 1.4978C10.128 1.4978 9.80901 1.55237 9.51023 1.65169C9.21145 1.55237 8.89242 1.4978 8.56073 1.4978C6.89648 1.4978 5.5425 2.85179 5.5425 4.51604V7.05976H3.82584C3.70307 7.05976 3.60112 7.15449 3.59212 7.27693L2.64347 20.1568C2.63348 20.2927 2.74097 20.4084 2.87723 20.4084H10.6739V21.4929C10.6739 21.6995 10.8509 21.8623 11.0567 21.8444C12.9958 21.6755 14.7836 21.2871 16.3415 20.7701C16.4859 20.7222 16.5833 20.5874 16.5833 20.4353V13.2006C16.5833 12.9764 16.377 12.809 16.1578 12.8558C16.0543 12.878 15.9496 12.8997 15.8441 12.9211L15.5559 9.00638C15.852 9.09694 16.1408 9.19538 16.4214 9.30165C16.5367 9.34533 16.6598 9.2603 16.6598 9.13702V5.89646C16.6598 5.81504 16.6042 5.74397 16.525 5.72504V5.72504ZM7.4415 7.05976H6.48V4.51604C6.48 3.47265 7.25207 2.6064 8.25487 2.45799C7.75087 2.99757 7.4415 3.72118 7.4415 4.51604V7.05976ZM8.379 4.51604C8.379 3.71068 8.83926 3.01126 9.51023 2.66555C10.1812 3.01126 10.6415 3.71068 10.6415 4.51604V7.05976H8.379V4.51604ZM12.5405 7.05976H11.579V4.51604C11.579 3.72118 11.2696 2.99757 10.7656 2.45799C11.7684 2.6064 12.5405 3.47265 12.5405 4.51604V7.05976Z" fill="url(#paint2_linear_4516_5327)" />
                                                    <path d="M2.97083 3.40723H2.50208C2.24361 3.40723 2.03333 3.19695 2.03333 2.93848V2.46973C2.03333 2.21126 2.24361 2.00098 2.50208 2.00098H2.97083C3.22929 2.00098 3.43958 2.21126 3.43958 2.46973V2.93848C3.43958 3.19695 3.22929 3.40723 2.97083 3.40723V3.40723ZM2.50208 2.46973V2.93876L2.97083 2.93848V2.46973H2.50208Z" fill="#FF6C6C" />
                                                    <path d="M22.1149 20.8145H21.6461C21.3876 20.8145 21.1774 20.6042 21.1774 20.3457V19.877C21.1774 19.6185 21.3876 19.4082 21.6461 19.4082H22.1149C22.3733 19.4082 22.5836 19.6185 22.5836 19.877V20.3457C22.5836 20.6042 22.3733 20.8145 22.1149 20.8145ZM21.6461 19.877V20.346L22.1149 20.3457V19.877H21.6461Z" fill="#FF6C6C" />
                                                    <path d="M16.8109 4.39062H16.3422C16.0837 4.39062 15.8734 4.18034 15.8734 3.92188V3.45312C15.8734 3.19466 16.0837 2.98438 16.3422 2.98438H16.8109C17.0694 2.98438 17.2797 3.19466 17.2797 3.45312V3.92188C17.2797 4.18034 17.0694 4.39062 16.8109 4.39062ZM16.3422 3.45312V3.92216L16.8109 3.92188V3.45312H16.3422Z" fill="#6C6CFF" />
                                                    <path d="M3.7439 5.73242C3.6839 5.73242 3.62394 5.70955 3.57815 5.6638L3.50727 5.59292C3.37935 5.46495 3.17104 5.46495 3.04297 5.59292L2.9721 5.6638C2.88055 5.7553 2.73219 5.7553 2.64065 5.6638C2.5949 5.618 2.57202 5.55805 2.57202 5.49805C2.57202 5.43805 2.5949 5.37809 2.64065 5.3323L2.71152 5.26142C2.77354 5.19941 2.80771 5.11695 2.80771 5.0293C2.80771 4.94164 2.77354 4.85919 2.71157 4.79717L2.64065 4.7263C2.5949 4.6805 2.57202 4.62055 2.57202 4.56055C2.57202 4.50055 2.5949 4.44059 2.64065 4.3948C2.73219 4.3033 2.88055 4.3033 2.9721 4.3948L3.04297 4.46567C3.17094 4.59364 3.37926 4.59364 3.50722 4.46567L3.5781 4.3948C3.66965 4.3033 3.81801 4.3033 3.90955 4.3948C3.9553 4.44059 3.97818 4.50055 3.97818 4.56055C3.97818 4.62055 3.9553 4.6805 3.90955 4.7263L3.83868 4.79717C3.71066 4.92514 3.71066 5.13345 3.83868 5.26142L3.90955 5.3323C3.9553 5.37809 3.97818 5.43805 3.97818 5.49805C3.97818 5.55805 3.9553 5.618 3.90955 5.6638C3.86385 5.70955 3.8039 5.73242 3.7439 5.73242V5.73242Z" fill="#FF9C33" />
                                                    <path d="M9.3708 23.1479C9.3108 23.1479 9.25085 23.1251 9.20505 23.0793L9.13418 23.0084C9.00621 22.8805 8.7979 22.8805 8.66993 23.0084L8.59905 23.0793C8.50751 23.1708 8.35915 23.1708 8.2676 23.0793C8.22185 23.0335 8.19897 22.9736 8.19897 22.9136C8.19897 22.8536 8.22185 22.7936 8.2676 22.7478L8.33847 22.6769C8.46649 22.549 8.46649 22.3407 8.33847 22.2127L8.2676 22.1418C8.22185 22.096 8.19897 22.0361 8.19897 21.9761C8.19897 21.9161 8.22185 21.8561 8.2676 21.8103C8.35915 21.7188 8.50751 21.7188 8.59905 21.8103L8.66993 21.8812C8.79785 22.0092 9.00616 22.0092 9.13422 21.8812L9.2051 21.8103C9.29665 21.7188 9.44501 21.7188 9.53655 21.8103C9.5823 21.8561 9.60518 21.9161 9.60518 21.9761C9.60518 22.0361 9.5823 22.096 9.53655 22.1418L9.46568 22.2127C9.40366 22.2747 9.36949 22.3572 9.36949 22.4448C9.36949 22.5325 9.40366 22.6149 9.46563 22.6769L9.53655 22.7478C9.5823 22.7936 9.60518 22.8536 9.60518 22.9136C9.60518 22.9736 9.5823 23.0335 9.53655 23.0793C9.49076 23.125 9.4308 23.1479 9.3708 23.1479Z" fill="#FF9C33" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Shopping</h3>
                                                    <span className="secondary_color fw_4">7/1/2023</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="noti-list">
                                            <div className="icon-box bg_service-6">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99995 14.7062C12.0609 14.7062 15.353 11.4141 15.353 7.35309C15.353 3.29209 12.0609 0 7.99995 0C3.93894 0 0.646851 3.29209 0.646851 7.35309C0.646851 11.4141 3.93894 14.7062 7.99995 14.7062Z" fill="#F7B239" />
                                                    <path d="M7.99997 12.1931C10.673 12.1931 12.8399 10.0261 12.8399 7.35312C12.8399 4.6801 10.673 2.51318 7.99997 2.51318C5.32695 2.51318 3.16003 4.6801 3.16003 7.35312C3.16003 10.0261 5.32695 12.1931 7.99997 12.1931Z" fill="#E09B2D" />
                                                    <path d="M14.6157 15.1809C14.6307 15.2373 14.638 15.294 14.638 15.3495C14.638 15.637 14.4463 15.8997 14.1558 15.9776C13.8089 16.0706 13.4521 15.8646 13.3591 15.5178L12.2661 11.4386C12.1731 11.0918 12.3791 10.7349 12.726 10.6419C13.0732 10.549 13.4297 10.755 13.5227 11.1022L14.6157 15.1809Z" fill="#F24C8F" />
                                                    <path d="M2.4596 11.5182L3.01855 11.668L4.49394 6.16149L3.93499 6.01173L2.4596 11.5182Z" fill="#CCCCCC" />
                                                    <path d="M11.6446 7.71971L12.4469 10.7156L13.0059 10.5659L12.2036 7.57L11.6446 7.71971Z" fill="#CCCCCC" />
                                                    <path d="M5.26403 2.1712C6.18881 2.41888 6.60972 3.8456 6.20463 5.3572C5.79953 6.86917 4.88478 7.28545 3.96 7.03776C3.03559 6.79007 2.4515 5.97217 2.85659 4.4602C3.26169 2.94823 4.33963 1.92351 5.26403 2.1712Z" fill="#E6E6E6" />
                                                    <path d="M2.9944 10.642C3.34162 10.7349 3.54727 11.0918 3.45427 11.4386L2.36168 15.5178C2.28374 15.8083 2.02099 16 1.73359 16C1.67802 16 1.6213 15.9927 1.56499 15.9776C1.21777 15.8847 1.01174 15.5278 1.10471 15.181L2.19771 11.1022C2.29068 10.755 2.64755 10.549 2.9944 10.642Z" fill="#F24C8F" />
                                                    <path d="M13.0658 6.02202L12.141 2.57046C12.1045 2.4344 11.9629 2.35446 11.8268 2.39515C11.6951 2.43455 11.6237 2.57771 11.6592 2.71046L12.3966 5.46168C12.4517 5.66743 12.3296 5.87893 12.1239 5.93408L12.1235 5.93415C11.9177 5.98937 11.7061 5.86721 11.651 5.66143L10.911 2.89987C10.8745 2.76371 10.7327 2.6838 10.5967 2.72458C10.465 2.76405 10.3937 2.90718 10.4293 3.0399L11.1666 5.79137C11.2217 5.99715 11.0996 6.20865 10.8938 6.26383C10.688 6.31896 10.4766 6.19687 10.4214 5.99108L9.68139 3.22937C9.64493 3.09324 9.5033 3.01337 9.36721 3.05405C9.23552 3.09346 9.16408 3.23658 9.19964 3.36933L10.1216 6.81049C10.2269 7.20383 10.479 7.53255 10.8316 7.73615C11.0665 7.87177 11.327 7.94102 11.5912 7.94102C11.7236 7.94102 11.857 7.92358 11.9883 7.8884C12.6534 7.71015 13.1178 7.10524 13.1178 6.4174C13.1178 6.28349 13.1003 6.15027 13.0658 6.02202Z" fill="#E6E6E6" />
                                                </svg>
                                            </div>
                                            <div className="content-right">
                                                <div className="title">
                                                    <h3 className="fw_4 secondary_color">Travel</h3>
                                                    <span className="secondary_color fw_4">1 Day Ago</span>
                                                </div>
                                                <div className="desc">
                                                    <p className="secondary_color fw_4">Alipay gives you 20% offcoupon for all bills, applicable for bills paid through Alipay wallet!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tf-panel down">
                <div className="panel_overlay" />
                <div className="panel-box panel-down">
                    <div className="header bg_white_color">
                        <div className="tf-container">
                            <div className="tf-statusbar d-flex justify-content-center align-items-center">
                                <Link to="#" className="clear-panel"> <i className="icon-close1" /> </Link>
                                <h3>Transfer Method</h3>
                                <Link to="40_qr-code.html" className="action-right"><i className="icon-qrcode4" /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-transfer mb-5">
                        <div className="tf-container">
                            <Link to="16_transfer-friend.html" className="action-sheet-transfer">
                                <div className="icon"><i className="icon-friends" /></div>
                                <div className="content">
                                    <h4 className="fw_6">Transfer to Friend</h4>
                                    <p>Free, just phone number</p>
                                </div>
                            </Link>
                            <Link to="23_transfer-bank.html" className="action-sheet-transfer">
                                <div className="icon"><i className="icon-bank2" /></div>
                                <div className="content">
                                    <h4 className="fw_6">Transfer to Bank</h4>
                                    <p>Transfer to bank account, card</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tf-panel card-popup">
                <div className="panel_overlay" />
                <div className="panel-box panel-down">
                    <div className="header">
                        <div className="tf-container">
                            <div className="tf-statusbar d-flex justify-content-center align-items-center">
                                <Link to="#" className="clear-panel"> <i className="icon-left" /> </Link>
                                <h3>Manage Your Card</h3>
                            </div>
                        </div>
                    </div>
                    <div className="content-card mt-3 mb-5">
                        <div className="tf-container">
                            <div className="tf-card-list bg_surface_color large out-line">
                                <div className="logo">
                                    <img src="assets/images/logo-banks/card-visa.png" alt="image" />
                                </div>
                                <div className="info">
                                    <h4 className="fw_6"><Link to="38_card-detail.html">Mastercard</Link></h4>
                                    <p>****  ****  ****   7576</p>
                                </div>
                                <input type="checkbox" className="tf-checkbox circle-check" defaultChecked />
                            </div>
                            <p className="auth-line">Choose other card for payment</p>
                            <ul className="box-card">
                                <li className="tf-card-list medium bt-line">
                                    <div className="logo">
                                        <img src="assets/images/logo-banks/card-visa2.png" alt="image" />
                                    </div>
                                    <div className="info">
                                        <h4 className="fw_6"><Link to="38_card-detail.html">Visacard</Link></h4>
                                        <p>****  ****  ****   3245</p>
                                    </div>
                                    <input type="checkbox" className="tf-checkbox circle-check" />
                                </li>
                                <li className="tf-card-list medium bt-line">
                                    <div className="logo">
                                        <img src="assets/images/logo-banks/card-visa.png" alt="image" />
                                    </div>
                                    <div className="info">
                                        <h4 className="fw_6"><Link to="38_card-detail.html">Mastercard</Link></h4>
                                        <p>****  ****  ****   7576</p>
                                    </div>
                                    <input type="checkbox" className="tf-checkbox circle-check" />
                                </li>
                                <li className="tf-card-list medium">
                                    <div className="logo">
                                        <img src="assets/images/logo-banks/card-visa2.png" alt="image" />
                                    </div>
                                    <div className="info">
                                        <h4 className="fw_6"><Link to="38_card-detail.html">Visacard</Link></h4>
                                        <p>****  ****  ****   7214</p>
                                    </div>
                                    <input type="checkbox" className="tf-checkbox circle-check" />
                                </li>
                            </ul>
                            <div className="tf-spacing-20" />
                            <Link to="34_card.html" className="tf-btn large">Add a new card <i className="icon-plus fw_7" /> </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tf-panel repicient">
                <div className="panel-box panel-up">
                    <div className="header-transfer header-st2">
                        <div className="tf-container">
                            <div className="tf-statusbar d-flex justify-content-between align-items-center">
                                <Link to="#" className="clear-panel"><i className="icon-left on_surface_color" /></Link>
                                <h3 className>Saved Repicients</h3>
                                <Link to="57_add-new-repicient.html" className="action-right"><i className="icon-plus" /> </Link>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-transfer-friends mt-3">
                        <div className="tf-container">
                            <div className="wrap-fixed">
                                <div className="input-field">
                                    <span className="icon-search" />
                                    <input required className="search-field value_input" placeholder="Search" type="text" defaultValue="Andy Cody" />
                                    <span className="icon-clear" />
                                </div>
                            </div>
                            <div className="tf-tab">
                                <ul className="menu-tabs">
                                    <li className="nav-tab active">Phone</li>
                                    <li className="nav-tab">Bank Account</li>
                                </ul>
                                <div className="content-tab">
                                    <ul className="tabs-list-item">
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user2.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Andy Cody</h4>
                                                        <p>****  ****  ****  0674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>A</li>
                                                    <li>B</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user3.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Alex Tran</h4>
                                                        <p>****  ****  ****  3216</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>C</li>
                                                    <li>D</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user8.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Themesflat</h4>
                                                        <p>****  ****  ****  0674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>E</li>
                                                    <li>F</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user9.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Albert Flores</h4>
                                                        <p>****  ****  ****   3674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>G</li>
                                                    <li>H</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user10.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Bruce Banner</h4>
                                                        <p>****  ****  ****  2432</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>I</li>
                                                    <li>I</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user11.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Benny</h4>
                                                        <p>****  ****  ****  2341</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>J</li>
                                                    <li>K</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user12.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Christian</h4>
                                                        <p>****  ****  ****  1255</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>L</li>
                                                    <li>M</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user13.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Cameron Williamson</h4>
                                                        <p>****  ****  ****  2352</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>O</li>
                                                    <li>P</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user14.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Cody Fisher</h4>
                                                        <p>****  ****  ****  0674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>Q</li>
                                                    <li>R</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user2.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Andy Cody</h4>
                                                        <p>****  ****  ****  0674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>S</li>
                                                    <li>T</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user3.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Alex Tran</h4>
                                                        <p>****  ****  ****  3216</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <p>U</p>
                                                    <p>V</p>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user8.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Themesflat</h4>
                                                        <p>****  ****  ****  0674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>W</li>
                                                    <li>X</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user9.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Albert Flores</h4>
                                                        <p>****  ****  ****   3674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <p>Y</p>
                                                    <p>Z</p>
                                                </ul>
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul className="tabs-list-item">
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user2.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Andy Cody</h4>
                                                        <p>****  ****  ****  0674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>A</li>
                                                    <li>B</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user3.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Alex Tran</h4>
                                                        <p>****  ****  ****  3216</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>C</li>
                                                    <li>D</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user8.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Themesflat</h4>
                                                        <p>****  ****  ****  0674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>E</li>
                                                    <li>F</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user9.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Albert Flores</h4>
                                                        <p>****  ****  ****   3674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>G</li>
                                                    <li>H</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user10.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Bruce Banner</h4>
                                                        <p>****  ****  ****  2432</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>I</li>
                                                    <li>I</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user11.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Benny</h4>
                                                        <p>****  ****  ****  2341</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>J</li>
                                                    <li>K</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user12.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Christian</h4>
                                                        <p>****  ****  ****  1255</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>L</li>
                                                    <li>M</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user13.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Cameron Williamson</h4>
                                                        <p>****  ****  ****  2352</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>O</li>
                                                    <li>P</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user14.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Cody Fisher</h4>
                                                        <p>****  ****  ****  0674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>Q</li>
                                                    <li>R</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user2.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Andy Cody</h4>
                                                        <p>****  ****  ****  0674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>S</li>
                                                    <li>T</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user3.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Alex Tran</h4>
                                                        <p>****  ****  ****  3216</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <p>U</p>
                                                    <p>V</p>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user8.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Themesflat</h4>
                                                        <p>****  ****  ****  0674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <li>W</li>
                                                    <li>X</li>
                                                </ul>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="54_repicient-detail.html" className="recipient-list">
                                                <ul className="inner">
                                                    <li className="user">
                                                        <img src="assets/images/user/user9.jpg" alt="image" />
                                                    </li>
                                                    <li className="info">
                                                        <h4>Albert Flores</h4>
                                                        <p>****  ****  ****   3674</p>
                                                    </li>
                                                </ul>
                                                <ul className="alphabet">
                                                    <p>Y</p>
                                                    <p>Z</p>
                                                </ul>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Home;