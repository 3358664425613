import React, { useState } from 'react'
import {ToastContainer, toast } from 'react-toastify';

function Login() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading,setLoadning]=useState(false);

    const loginApi = process.env.REACT_APP_API_URL
    const apiKey = process.env.REACT_APP_API_KEY


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

    const tryLogin = () =>{
        setLoadning(true);


        if(username != "" && password != "")
            {

                    const response =  fetch(loginApi+'login.php?username='+username+'&password='+password+'&apiKey='+apiKey)
                        .then(res => res.json())
                        .then(
                        (result) => {

                            console.log("Return Data", result);
                            if(result.error == "200"){

                                toast.success(result.message,{
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            localStorage.setItem('id', result.id);
                            localStorage.setItem('sponsor_id', result.sponsor_id);
                            localStorage.setItem('name', result.name);
                            localStorage.setItem('mobile', result.mobile);
                            localStorage.setItem('myBusiness', result.myBusiness);
                            localStorage.setItem('teamBusiness', result.teamBusiness);
                            localStorage.setItem('allBusiness', result.allBusiness);
                            localStorage.setItem('joidDate', result.joidDate);
                            localStorage.setItem('joinTime', result.joinTime);
                            localStorage.setItem('lastLogin', result.lastLogin);
                            localStorage.setItem('LoginCount', result.LoginCount);
                            localStorage.setItem('accountStatus', result.accountStatus);
                            localStorage.setItem('signupPoint', result.signupPoint);
                            localStorage.setItem('referralPoint', result.referralPoint);
                            localStorage.setItem('totalPoint', result.totalPoint);
                            localStorage.setItem('usedPoint', result.usedPoint);
                            localStorage.setItem('transferPoint', result.transferPoint);
                            localStorage.setItem('avlPoint', result.avlPoint);
                            localStorage.setItem('emailStatus', result.mailStatus);
                            localStorage.setItem('directIncome', result.directIncome);
                            localStorage.setItem('depositAmount', result.depositAmount);
                            localStorage.setItem('totalAmount', result.totalAmount);
                            localStorage.setItem('directWithdraw', result.directWithdraw);
                            localStorage.setItem('directAvailable', result.directAvailable);
                            localStorage.setItem('stakeAmount', result.stakeAmount);
                            localStorage.setItem('stakeCoin', result.stakeCoin);
                            localStorage.setItem('finalCoin', result.finalCoin);
                            localStorage.setItem('creditCoin', result.creditCoin);
                            localStorage.setItem('remainCoin', result.remainCoin);
                            localStorage.setItem('withdrawCoin', result.withdrawCoin);
                            localStorage.setItem('swapCoin', result.swapCoin);
                            localStorage.setItem('avlCoin', result.avlCoin);
                            localStorage.setItem('withdrawStatus', result.withdrawStatus);
                            localStorage.setItem('swapStatus', result.swapStatus);
                            localStorage.setItem('directReferral', result.directReferral);
                            localStorage.setItem('totalTeam', result.totalTeam);
                            localStorage.setItem('team1', result.team1);
                            localStorage.setItem('team2', result.team2);
                            localStorage.setItem('team3', result.team3);
                            localStorage.setItem('team4', result.team4);
                            localStorage.setItem('team5', result.team5);
                            localStorage.setItem('team6', result.team6);
                            localStorage.setItem('team7', result.team7);
                            localStorage.setItem('team8', result.team8);
                            localStorage.setItem('team9', result.team9);
                            localStorage.setItem('team10', result.team10);
                            localStorage.setItem('team11', result.team11);
                            localStorage.setItem('team12', result.team12);
                            localStorage.setItem('team13', result.team13);
                            localStorage.setItem('team14', result.team14);
                            localStorage.setItem('team15', result.team15);
                            localStorage.setItem('businessLevel1', result.businessLevel1);
                            localStorage.setItem('businessLevel2', result.businessLevel2);
                            localStorage.setItem('businessLevel3', result.businessLevel3);
                            localStorage.setItem('businessLevel4', result.businessLevel4);
                            localStorage.setItem('businessLevel5', result.businessLevel5);
                            localStorage.setItem('businessLevel6', result.businessLevel6);
                            localStorage.setItem('businessLevel7', result.businessLevel7);
                            localStorage.setItem('businessLevel8', result.businessLevel8);
                            localStorage.setItem('businessLevel9', result.businessLevel9);
                            localStorage.setItem('businessLevel10', result.businessLevel10);
                            localStorage.setItem('businessLevel11', result.businessLevel11);
                            localStorage.setItem('businessLevel12', result.businessLevel12);
                            localStorage.setItem('businessLevel13', result.businessLevel13);
                            localStorage.setItem('businessLevel14', result.businessLevel14);
                            localStorage.setItem('businessLevel15', result.businessLevel15);
                            localStorage.setItem('rewardLevel1', result.rewardLevel1);
                            localStorage.setItem('rewardLevel2', result.rewardLevel2);
                            localStorage.setItem('rewardLevel3', result.rewardLevel3);
                            localStorage.setItem('rewardLevel4', result.rewardLevel4);
                            localStorage.setItem('rewardLevel5', result.rewardLevel5);
                            localStorage.setItem('rewardLevel6', result.rewardLevel6);
                            localStorage.setItem('rewardLevel7', result.rewardLevel7);
                            localStorage.setItem('rewardLevel8', result.rewardLevel8);
                            localStorage.setItem('rewardLevel9', result.rewardLevel9);
                            localStorage.setItem('rewardLevel10', result.rewardLevel10);
                            localStorage.setItem('rewardLevel11', result.rewardLevel11);
                            localStorage.setItem('rewardLevel12', result.rewardLevel12);
                            localStorage.setItem('rewardLevel13', result.rewardLevel13);
                            localStorage.setItem('rewardLevel14', result.rewardLevel14);
                            localStorage.setItem('rewardLevel15', result.rewardLevel15);
                            if(result.mailStatus === 'verified')
                            {
                            
                                sessionStorage.setItem('userID', result.id);
                                if(sessionStorage.getItem('userID') != "")
                                {
                                      window.location.href = "/";

                                }
                                else
                                {

                                    setLoadning(false);
                                    toast.error("Try Again",{
                                        position: toast.POSITION.TOP_RIGHT,
                                    });

                                }

                            }else{

                                localStorage.setItem('otpmail', result.email);
                                toast.error('Please first Verify Email' ,{
                                    position: toast.POSITION.TOP_RIGHT,
                                });

                                window.location.href = "/verifyaccount";

                             }   
                            };

                            if(result.error == "100"){

                                setLoadning(false);

                                toast.error(result.message ,{
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            
                            };

                        }
                        )

                        if (!response.ok) {
                        console.log(response.error)
                        }
                  
            }
            else
            {
                setLoadning(false);
                toast.warning('Please Enter All Filed.',{
                    position: toast.POSITION.TOP_RIGHT,
                });

            }

    }



    // console.log(sessionStorage.getItem('SponsorID'));
    return (
        <div className="mt-7 login-section">
            <div className="tf-container">
                <form className="tf-form" >
                    <h1>Login</h1>
                    <ToastContainer />
                    <div className="group-input">
                        <label>Email or Mobile</label>
                        <input type="text" placeholder="Email or Mobile Numer" onChange={(event)=>setUsername(event.target.value)}  />
                    </div>
                    <div className="group-input auth-pass-input last">
                        <label>Password</label>
                        <input type={showPassword ? 'text' : 'password'} className="password-input" placeholder="Password" onChange={(event)=>setPassword(event.target.value)} />
                        <a className="password-addon" href="javascript:void(0)" onClick={togglePasswordVisibility}>{showPassword ? '👁️' : '🙈'}</a>
                    </div>
                    <a href="08_reset-password.html" className="auth-forgot-password mt-3">Forgot Password?</a>
                    

                    {isLoading ? "" :
                            (<button type="button"  onClick={tryLogin} className="tf-btn accent large">Login</button>)}
                        {isLoading ? <div className="text-center">
                        <span className="text-center"><img src="./loading.gif" style={{width:"60px",height:"40px"}} /></span>
                        </div> :'' }
                </form>
                <div className="auth-line">Or</div>
                {/* <ul className="bottom socials-login mb-4">
                    <li><a href="/"><img src="assets/images/icon-socials/facebook.png" alt="image" />Continue with Facebook</a></li>
                    <li><a href="/"><img src="assets/images/icon-socials/google.png" alt="image" />Continue with Google</a></li>
                    <li><a href="/"><img src="assets/images/icon-socials/apple.png" alt="image" />Continue with Google</a></li>
                </ul> */}
                <p className="mb-9 fw-3 text-center ">Already have a Account? <a href="/Register" className="auth-link-rg">Sign up</a></p>
            </div>
        </div>
    );
}
export default Login;