import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Auth from '../Components/auth';
import { ToastContainer,toast } from 'react-toastify';

function Stake() {

    const [stakeAmount, setStakeAmount] = useState('50');
    const coinPrice =  localStorage.getItem("adminCoinPrice");
    const stakeCoinAmount = stakeAmount/coinPrice;

    const [tableData, setTabledata] = useState([]);
    const [isData,setData]=useState(false);



    console.log("Stake Amount :" +stakeAmount );


    

    const [isLoading,setLoadning]=useState(false);
          


    const startMining = async()=>{
        try {

           

             setLoadning(true);

                const userId = localStorage.getItem('id');

                console.log("Stake Amount :" +stakeAmount+" or "+userId);

         await   fetch('https://api.rainocoin.com/startMining.php?userid='+userId+'&usdtAmount='+stakeAmount+'&coinAmount='+stakeCoinAmount)
                    .then(wr => wr.json())
                    .then( mining => {

                    console.log(mining.message);
                    
                    if(mining.error === '200'){
                        setLoadning(false);

                        toast.success(mining.message,{ position: toast.POSITION.TOP_RIGHT, });

                    };
                    if(mining.error === '400'){

                        setLoadning(false);

                        toast.error(mining.message,{ position: toast.POSITION.TOP_RIGHT, });
                       }
                     }
                    )
            
        } catch (error) {
            console.log(error);

            setLoadning(false);
        }

    };

    const getHistory = () =>{

        fetch('https://api.rainocoin.com/miningHistory.php?userid='+localStorage.getItem('id'))
        .then(res => res.json())
        .then(
            (downline) =>{

                console.log("My Referral "+ downline.error);

                if(downline.error === '200'){
                    
                    setData(true)

                    setTabledata(downline.data)

                }

                if(downline.error === '400'){
                    setData(false)
                    

                }
            }
        )
    }

    
    useEffect(() =>{

        getHistory();
    
    },[])

  

    return (
        <>
            <div className="app-header st1">
            <Auth/>
            <ToastContainer />
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-center align-items-center">
                        <Link to="/" className="back-btn"><i className="icon-left white_color"></i></Link>
                        <h3 className="white_color">Start Mining</h3>
                    </div>
                </div>
            </div>
            
        
            <div className="card-secton topup-content">
                <div className="tf-container">
                    <div className="tf-balance-box">
                        <div className="d-flex justify-content-between align-items-center">
                            <p>Amount : <span class="text-primary">{localStorage.getItem('directAvailable')} USDT</span></p>
                        </div>
                        <div className="tf-spacing-16"></div>
                        <div className="tf-form">
                        <div className="group-input auth-pass-input last mt-3">
                            <label>Select Amount</label>
                            <select name="cars" id="asset" class="password-input" onChange={(event)=>setStakeAmount(event.target.value)}>
                                <option value="50">50 USDT</option>
                                <option value="100">100 USDT</option>
                                <option value="250">250 USDT</option>
                                <option value="500">500 USDT</option>
                                <option value="1000">1000 USDT</option>
                            </select>
                        </div>
                        <div className="group-input auth-pass-input last mt-3">
                            <label>Enter Amount</label>
                            <input type="number" className="password-input" placeholder="Enter Money" value={stakeCoinAmount}  />
                        </div>
                        <div className="group-input auth-pass-input last mt-5 text-center">
                        {isLoading ? "" :
                            (<button  className="tf-btn accent large" onClick={startMining} >Start Mining</button>)}
                        
                        {isLoading ? <div className="text-center">
                        <span className="text-center"><img src="./waiting.gif" style={{width:"100px",height:"40px"}} /></span> 
                        </div> :'' }
                        </div>
                        </div>
                    </div>

                </div>
            </div>
            <div id="app-wrap">
                <div className="app-section st1 mt-1 mb-5 bg_white_color">
                    <div className="tf-container">
                    <div className="trading-month">
                            <div className="group-trading-history mb-5">
                            {isData ?
                            <div>

                            {tableData.map(item =>(
                                
                                <Link className="tf-trading-history" to="javascript:void(0)">
                                    <div className="inner-left">
                                        <div className="icon-box">
                                            <i className="icon icon-mobile" />
                                        </div>
                                        <div className="content">
                                            <h4>{item.amount} USDT</h4>
                                            <p>{item.stakeCoin} RAINO</p>
                                        </div>
                                    </div>
                                    <span className="num-val success_color ">{item.DateTime}</span>
                                </Link>
                                ))}

                            
                            </div>
                            :
                            <div>
                            <Link className="tf-trading-history" to="javascript:void(0)">
                                    <div className="inner-left">
                                        <div className="icon-box">
                                            <i className="icon icon-mobile" />
                                        </div>
                                        <div className="content">
                                            <h4>No Any History</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            }
                            
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            
        </>
    )
}

export default Stake;