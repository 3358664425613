import React, { useState } from 'react';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Register() {


    const Api = process.env.REACT_APP_API_URL
    const apiKey = process.env.REACT_APP_API_KEY

    //Sponsor ID Store Data in device Start //
    const urlParams = new URLSearchParams(window.location.search);
    const sponsorCode = urlParams.get('invite');
    if(sponsorCode != ""){
        sessionStorage.setItem('SponsorID', sponsorCode);
        const sponsor = sponsorCode;
    };
    const sponsor = sessionStorage.getItem('SponsorID');
  //Sponsor ID Store Data in device End //


  const [isLoading,setLoadning]=useState(false);
  const [disabl,setDisable]=useState("");



    const [name, setName] = useState(null);
    const [mail, setMail] = useState(null);
    const [mobile, setMobile] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    

    //Show Password Script Start //
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };
      //Show Password Script End //


    // Login Script Start//

    const submitForm = ()=>{
        
        setLoadning(true);
        
            if(name != "" && mail != ""  && mobile != "" && password != "" && confirmPassword != "")
            {

                if(password == confirmPassword)
                  {

                    

                  //  console.log("Password :", name +" - "+ mail+"-"+mobile+" - "+password+"-"+confirmPassword);

                    const response =  fetch(Api+'register.php?name='+name+'&email='+mail+'&mobile='+mobile+'&password='+password+'&sponsor='+sponsor+'&apiKey='+apiKey)
                        .then(res => res.json())
                        .then(
                        (result) => {

                            console.log("Return Data", result);
                            if(result.error == "200"){

                                toast.success(result.message,{
                                    position: toast.POSITION.TOP_RIGHT,
                                });

                                localStorage.setItem('otpmail', mail);

                                window.location.href = "/Verifyaccount";
                            
                            };

                            if(result.error == "100"){

                                setLoadning(false);

                                toast.error(result.message ,{
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            
                            };

                        }
                        )

                        if (!response.ok) {
                        console.log("fetch failed")
                        }
                  }
                  else
                  {
                    setLoadning(false);
                        toast.error('Password Miss Matched.',{
                            position: toast.POSITION.TOP_RIGHT,
                        });
                  }
            }
            else
            {
                setLoadning(false);
                toast.warning('Please Enter All Filed.',{
                    position: toast.POSITION.TOP_RIGHT,
                });

            }

        
    }

      //Lofin Script End//
    


    return (
        <div>
            <div className="header">
                <div className="tf-container">
                    <div className="tf-statusbar br-none d-flex justify-content-center align-items-center">
                        <a href="/login" className="back-btn"> <i className="icon-left" /> </a>
                    </div>
                </div>
            </div>
            <div className="mt-3 register-section">
                <div className="tf-container">
                    <form className="tf-form" >
                        <h1>Register</h1>
                        <ToastContainer />
                        <div className="group-input">
                            <label>Name</label>
                            <input type="text" onChange={(event)=>setName(event.target.value)}  required="" />
                        </div>
                        <div className="group-input">
                            <label>Email</label>
                            <input type="email" onChange={(event)=>setMail(event.target.value)} equired="" />
                        </div>
                        <div className="group-input">
                            <label>Phone Number</label>
                            <input type="number" onChange={(event)=>setMobile(event.target.value)} equired="" />
                        </div>
                        <div className="group-input">
                            <label>Password</label>
                            <input type={showPassword ? 'text' : 'password'} onChange={(event)=>setPassword(event.target.value)} placeholder="6-20 characters" equired="" />
                        </div>
                        <div className="group-input auth-pass-input last">
                            <label>Comfirm Password</label>
                            <input type={showPassword ? 'text' : 'password'} className="password-input"  value={confirmPassword} onChange={(event)=>setConfirmPassword(event.target.value)} equired="" />
                            <a className="password-addon" href="javascript:void(0)" onClick={togglePasswordVisibility}>{showPassword ? '👁️' : '🙈'}</a>
                        </div>
                        <div className="group-cb mt-5">
                            <input type="checkbox" defaultChecked className="tf-checkbox"/>
                            <label className="fw_3">I agree to <a>Terms and condition</a></label>
                        </div>
                        {isLoading ? "" :
                            (<button type="button"  onClick={submitForm} className="tf-btn accent large">Create An Account</button>)}
                        {isLoading ? <div className="text-center">
                        <span className="text-center"><img src="./loading.gif" style={{width:"60px",height:"40px"}} /></span>
                        </div> :'' }
                        
                        

                    </form>
                    <div className="auth-line mt-5">Or</div>
                {/* <ul className="bottom socials-login mb-4">
                    <li><a href="/"><img src="assets/images/icon-socials/facebook.png" alt="image" />Continue with Facebook</a></li>
                    <li><a href="/"><img src="assets/images/icon-socials/google.png" alt="image" />Continue with Google</a></li>
                    <li><a href="/"><img src="assets/images/icon-socials/apple.png" alt="image" />Continue with Google</a></li>
                </ul> */}
                <p className="mb-9 fw-3 text-center ">Already have a Account? <a href="/login" className="auth-link-rg">Login up</a></p>
                </div>
            </div>
        </div>
    );
}
export default Register;